import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./navbar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import userIcon from '../../assets/icons/user-icon.png';
import { useLocation } from "react-router-dom";
import { APIs } from '../../shared/Config/config.js';
import { useSelector } from "react-redux";
import { axiosInstance } from "../../utils/axiosInstance";

function Nav() {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let getSponsored = sessionStorage.getItem("sponsored");
  let getCompanyName = sessionStorage.getItem("companyName")
  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     if (open && ref.current && !ref.current.contains(e.target)) {
  //       setOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [open]);

  useEffect(() => {
    sessionStorage.setItem("invitationCount", 0);
    let checkIfClickedOutside = e => {
      if (open && ref.current && !ref.current.contains(e.target) && !e.target.closest('.closeDropDown')) {
        setOpen(!open);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [open]);


  let logout = () => {
    let types = sessionStorage.getItem("loginType")

    const json = {
      "json": {
        "request": {
          "servicetype": sessionStorage.getItem("loginType") === "assessor" ? 9 : "4",
          "functiontype": sessionStorage.getItem("loginType") === "assessor" ? 9026 : "4028",
          "session": {
            "sessionid": sessionStorage.getItem("sessionid")
          },
        }
      }
    }
    let temp = encodeURIComponent(JSON.stringify(json))
    let apiUrl
    if (sessionStorage.getItem("loginType") === "assessor") {
      apiUrl = APIs.assessorAPI + temp;
    } else {
      apiUrl = APIs.employerProfileApi + temp;
    }

    axiosInstance.post(apiUrl).then((result) => {
      let temp = result.data.json.response;
      if (temp.statuscode = "0") {
        sessionStorage.clear();
        if (types === "assessor") {
          navigate('/Assessor-Login');
        } else {
          navigate('/');
        }
      }
    }).catch((err) => {
      if (err.response.status === 400) {
        sessionStorage.clear();
        if (types === "assessor") {
          navigate('/Assessor-Login');
        } else {
          navigate('/');
        }
      }
    })
  }

  const { recruiter } = useSelector((state) => state.home)

  return (
    <div className="card1 border-navbar">
      <div className="container-fluid customNavbar p-0">
        <div className="nav-bar">

          {getSponsored == "true" ? <div className="d-flex">
            <div className="navbar-header">
              <img className="logo-nav" src={sessionStorage.getItem("companyLogoId") <= 0 ? userIcon : APIs.downloadImage + sessionStorage.getItem("companyLogoId")} alt="Company Icon"
                onError={(e) => e.target.src = userIcon} />
            </div>
            <span className="menuTitle" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              {getCompanyName === "undefined" ? <span className="cName">&nbsp;Company Name</span> : null}
              {/* <br /> */}
              <strong>&nbsp;{getCompanyName === "undefined" ? <>-</> : getCompanyName}</strong>
              {/* <strong>&nbsp;{decodeURIComponent(sessionStorage.getItem("companyName"))}</strong> */}
            </span>
          </div> :
            <div style={{ paddingLeft: "23px", display: "flex", alignItems: "center" }}>
              <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: "0" }}>Welcome to Sh<span style={{ color: "#ff8a00" }}>o</span>rtlistd!</h3>
            </div>}
          <div className="d-flex">
            <div className="button-post-job mt-1">
              <Link to='/postjob'>
                {(splitLocation[1] === "postjob" || sessionStorage.getItem("loginType") == "assessor") ? "" :
                  <Button className="button-opp post-job-btn" type="submit">
                    POST A JOB
                  </Button>}
              </Link>
            </div>
            <div className="d-flex">
              <div className="navbar-header">
                {/* {sessionStorage.getItem("loginType") !== "assessor" ? */}
                <img className="logo-nav" src={sessionStorage.getItem("photoId") <= 0 ? userIcon : APIs.downloadImage + sessionStorage.getItem("photoId")} alt="User "
                  onError={(e) => e.target.src = userIcon} />
                {/* //   :
                //   (sessionStorage.getItem("photoId") !== "0" ?
                //     <img className="logo-nav" src={APIs.downloadImage + sessionStorage.getItem("photoId")} alt="User " />
                //     :
                //     <img className="logo-nav" src={userIcon} alt="User " />
                //   )
                // } */}
              </div>
              <span className="menuTitle">
                {sessionStorage.getItem("loginType") !== "assessor" ?
                  <><span className="font14">&nbsp;{sessionStorage.getItem("userName")}</span><span>{recruiter?.data?.companyAdmin ? " (Admin)" : " (Recruiter)"}</span></> :
                  <span className="font14">&nbsp;{sessionStorage.getItem("userName")} </span>}
                <br />
                <span className="font12">&nbsp;{sessionStorage.getItem("industry")}</span>
              </span>
              <div className="closeDropDown" ref={ref}>
                <div className="down-icon cur">
                  <i className="bi-chevron-down" onClick={() => setOpen(!open)}></i>
                </div>
                {open && (
                  <div className="menu-list logout">
                    <div className="list-item" onClick={logout}>Logout</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="navbar">
    //   <div className="nav-start">
    //     <div>
    //       <img />

    //       <div>
    //         <label>Campany</label>
    //         <br />
    //         <label>Raos Infosoft Join Pvt Ltd</label>
    //       </div>
    //     </div>
    //     <div className="d-flex">
    //       <div>
    //         <Button className="button" type="submit">
    //           SIGN UP
    //         </Button>
    //       </div>
    //       <div>
    //       <img />

    //       <div>
    //         <label>Campany</label>
    //         <br />
    //         <label>Raos Infosoft Join Pvt Ltd</label>
    //       </div>
    //     </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Nav;
