import React, { useState } from "react";
import { SuccessError } from "../../../../shared/Popup/SuccessError";
import userIcon from '../../../../assets/icons/user-icon.png';
import ReactPaginate from "react-paginate";
import axios from "axios";
import { APIs } from "../../../../shared/Config/config.js";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../../assets/gif/loader.gif";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Companyprofile from "../../../../shared/Popup/CompanyProfile/companyProfile";
import NoData from "../../../../assets/icons/nodata.png"
import { axiosInstance } from "../../../../utils/axiosInstance";

function Declined(props) {
    let navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [msg, setMsg] = useState();
    let [showSuccess, setShowSuccess] = useState(false);
    let [companyModal, setCompanyModal] = useState(false);
    let [getConfirmationData, setGetConfirmationData] = useState({});
    let [showConfirmationModal, setShowConfirmationModal] = useState(false);
    let [getCompanyProfileId, setGetCompanyProfileId] = useState();

    const handleErrorPopup = () => {
        setShowError(!showError)
    }

    let handleSuccess = () => {
        setShowSuccess(false);
    }

    let viewCompanyModal = (item) => {
        setGetCompanyProfileId(item);
        setCompanyModal(true);
    }

    let getConfirmationModal = (data, type) => {
        setGetConfirmationData({ data, type });
        setShowConfirmationModal(true);
    }

    let handleCloseModal = () => {
        setShowConfirmationModal(false);
    }

    const updateInvitation = (state, id) => {
        let payLoad = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9044",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "id": id,
                        "state": state,
                        "rejectReason": ""
                    }
                }
            }
        }

        let apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setShowSuccess(true);
                    setMsg(state === 2 ? "Your Partnership successfully Established" : "You Have Successfully Closed Partnership");
                    setShowConfirmationModal(false);
                    props.InvitaionList(0, props.stateInt, "")
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                    setShowConfirmationModal(false);
                    props.InvitaionList(0, props.stateInt, "")
                }
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.error.message);
                        setShowConfirmationModal(false);
                    } else {
                        
                    }
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            <SuccessError
                errorMsg={errorMsg}
                showError={showError}
                showSuccess={showSuccess}
                msg={msg}
                handleErrorPopup={handleErrorPopup}
                handleSuccess={handleSuccess} />

            {/* --------> view company modal <--------- */}
            {
                companyModal &&
                <Companyprofile
                    companyModal={companyModal}
                    setCompanyModal={setCompanyModal}
                    getCompanyProfileId={getCompanyProfileId} />
            }
            <div className="pending_partnerShip">
                <>
                    <div className="key_search">
                        <div className="vertical_border"></div>
                        <i className="fa fa-search icon-invite"></i>
                        <input
                            className="form-control input-field-invite"
                            type="text"
                            value={props.searchVal}
                            onChange={(e) => props.InvitaionList(0, props.stateInt, e.target.value)}
                            placeholder="Search company name"
                        ></input>
                    </div>
                    {props.getInvitaionList.length > 0 ?
                        <div className="main_cards_content">
                            <div className="cards">
                                {
                                    props.getInvitaionList?.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="header">
                                                {/* <div className="img"> */}
                                                {item.companyLogoId <= 0 ?
                                                    <img src={userIcon} alt="company logo" style={{
                                                        width: "90px",
                                                        borderRadius: "50%",
                                                        marginRight: "14px"
                                                    }} />
                                                    :
                                                    <img src={APIs.downloadImage + item.companyLogoId} alt="Company Icon"
                                                        onError={(e) => e.target.src = userIcon} style={{
                                                            width: "90px",
                                                            borderRadius: "50%",
                                                            marginRight: "14px"
                                                        }} />
                                                }
                                                {/* </div> */}
                                                <div className="card_content">
                                                    <p>Received on {moment.utc(item?.createdTime).local().format("DD MMM YYYY")}</p>
                                                    <label onClick={() => viewCompanyModal(item)}>{item?.companyName}</label>
                                                    <p><b>Charge per interview</b> : {item?.costnegotiated}</p>
                                                    <p style={{ textAlign: "justify" }} className={item?.message?.length <= 90 ? "msg" : "msgOver"}>{item?.message}</p>
                                                </div>
                                            </div>
                                            <div className="horizontal_border"></div>
                                            <div className="footer">
                                                <div className="decline" onClick={() => getConfirmationModal(item, "remove")}>
                                                    <i className="bi bi-x-circle"></i>
                                                    <label>Remove from List</label>
                                                </div>
                                                <div className="vertical_border"></div>
                                                <div className="accept view_partnerShip" onClick={() => getConfirmationModal(item, "reconsider")}>
                                                    <i className="bi bi-justify"></i>
                                                    <label>Reconsider</label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {props.pageCount > 1 &&
                                <div className="partnerShip_pagination">
                                    <ReactPaginate
                                        previousLabel={<i className="bi-arrow-left"></i>}
                                        nextLabel={<i className="bi-arrow-right"></i>}
                                        pageCount={props.pageCount}
                                        onPageChange={props.handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        forcePage={props.currentPage}
                                        previousClassName={props.currentPage == 0 ? "hidden" : ""}
                                        nextClassName={props.currentPage == props.pageCount - 1 ? "hidden" : ""}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className="no_data_found">
                            <div className="d-flex justify-content-center">
                                <img src={NoData} height={150} alt="no-data found" />
                            </div>
                        </div>
                    }
                </>
            </div>

            <Modal
                className="confirmation_modal"
                show={showConfirmationModal}
                onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <div className="header">
                        <h4>Confirmation {getConfirmationData?.type === "reconsider" ? <i className="bi bi-emoji-smile"></i> : <i className="bi bi-emoji-frown"></i>}</h4>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        {getConfirmationData?.type === "reconsider" ?
                            <p>Are you sure you want to <span style={{ color: "green", fontWeight: "500" }}>reconsider</span> the partnership with <b>{getConfirmationData?.data?.companyName}</b> ?</p>
                            :
                            <p>Are you sure you want to <span style={{ color: "red", fontWeight: "500" }}>remove</span> the partnership with <b>{getConfirmationData?.data?.companyName}</b>?</p>
                        }
                        <div className="btns">
                            {getConfirmationData?.type === "reconsider" ?
                                <button onClick={() => updateInvitation(2, getConfirmationData?.data?.id)}>Yes</button>
                                :
                                <button onClick={() => updateInvitation(-1, getConfirmationData?.data?.id)}>Yes</button>
                            }
                            <button onClick={() => handleCloseModal()}>No</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Declined;