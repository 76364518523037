import React, { useState } from 'react'
import './avatarImage.css';
import Avatar from "react-avatar-edit";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { APIs } from '../../../shared/Config/config.js';
import axios from 'axios';
import { axiosInstance } from '../../../utils/axiosInstance';

const AvatarImage = ({ onClose, cropPopup, setErrorMsg, setShowError, setMsg, setShowSuccess, imgCropModal }) => {

  const [croppedImage, setCroppedImage] = useState()

  const onCrop = async (view) => {

    const response = await fetch(view);
    console.log(response, "Response 63")
    const blob = await response.blob();
    console.log(blob, "Blob 63")
    const imageFile = new File([blob], 'cropped_image.jpg');

    setCroppedImage(imageFile);
  }


  const saveImage = async () => {
    if (!imgCropModal) {
      try {
        const formData = new FormData();
        formData.append('image', croppedImage);
        const json = {
          "json": {
            "request": {
              "servicetype": "4",
              "functiontype": "4026",
              "session": {
                "sessionid": sessionStorage.getItem("sessionid"),
              }
            }
          }
        }

        const apiUrl = APIs.employerProfileApi + encodeURIComponent(JSON.stringify(json));;

        const response = await axiosInstance.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          let temp = response.data.json.response
          if (temp.statuscode === "0") {
            sessionStorage.setItem("photoId", temp.data.photoId);
            setShowSuccess(true);
            setMsg("Profile photo uploaded successfully.")
            // getCompanyDetails()
            // setImgCrop()
          } else {
            setShowError(true)
            setErrorMsg(temp.statusmessage)
          }
        } else {
          setShowError(true);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setShowError(true);
      } finally {
        onClose();
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('image', croppedImage);
        const json = {
          "json": {
            "request": {
              "servicetype": "9",
              "functiontype": "9010",
              "session": {
                "sessionid": sessionStorage.getItem("sessionid")
              }
            }
          }
        }

        const apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(json));;

        const response = await axiosInstance.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          let temp = response.data.json.response
          if (temp.statuscode === "0") {
            sessionStorage.setItem("photoId", temp.data.photoId);
            setShowSuccess(true);
            setMsg("Profile photo uploaded successfully.")
            // getCompanyDetails()
            // setImgCrop()
          } else {
            setShowError(true)
            setErrorMsg(temp.statusmessage)
          }
        } else {
          setShowError(true);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setShowError(true);
      } finally {
        onClose();
      }
    }
  };

  return (
    <div>
      <Modal
        className="post_job_success_popup"
        show={cropPopup || imgCropModal}
        onHide={onClose}
        backdrop="static"
        dialogClassName="post-job-success-modal"
        keyboard={false}
      >
        <Modal.Header closeButton className="modal-title">
          <Modal.Title>
            <div className="modal-info">
              <div className="font18">{imgCropModal ? <>Upload Your Image</> : <>Upload Company Logo</>}</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <Avatar
              width={300}
              height={250}
              onCrop={onCrop}
              onClose={onClose}
              // onBeforeFileLoad={this.onBeforeFileLoad}
              src={""}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button className="save-button">Save</Button> */}
          <Button className="save-button" onClick={saveImage}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AvatarImage