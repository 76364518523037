import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { SuccessError } from "../shared/Popup/SuccessError";
import { useState } from "react";
import { APIs, BaseUrl } from "../shared/Config/config";

const axiosInstance = axios.create({
    // baseURL: 'https://restapi-qa.theshortlistd.com',
    baseURL: BaseUrl,
});

const setupAxiosInterceptors = (navigate, setSessionExpired, setShowError, setErrorMsg) => {
    axiosInstance.interceptors.response.use(
        (response) => {
            console.log(response)
            // If the response is successful, just return the response
            // if (response.data.json.response.statuscode !== "0" || response.data.json.response.statuscode == "20021") {
            //     setShowError(true);
            //     setErrorMsg(response.data.json.response.statusmessage);
            // }

            if(response.data.json.response.statuscode === "50097"){
                setSessionExpired(true);
            }
            return response;
        },
        (error) => {
            console.log(error);
            if (error.response.status === 400 || error.response.status === 401) {
                setSessionExpired(true);
            } else {
                setSessionExpired(false);
                if (error.response.status >= 400 && error.response.status < 500) {
                    // if (error.response.status !== 404) {
                    //     setShowError(true);
                    //     setErrorMsg(error?.response?.data?.errors?.statusmessage);
                    // }
                } else {
                    setShowError(false);
                    setErrorMsg("");
                    navigate("/server-error");
                }
            }
            // Return any error which is not due to authentication back to the calling service
            return Promise.reject(error);
        }
    );
};

const SessionExpiredModal = ({ onConfirm, setSessionExpired }) => {
    let navigate = useNavigate();

    setTimeout(() => {
        setSessionExpired(false);
        // navigate("/");
    }, 10000);

    return (
        <>
            <Modal
                className="confirmation_modal"
                show={onConfirm}>
                <Modal.Body>
                    <div className="content" style={{
                        display: "flex",
                        aligIitems: "center"
                    }}>
                        <i className="bi bi-exclamation-triangle" style={{
                            color: "#FF8A00", marginRight: "16px",
                            fontSize: "28px"
                        }}></i>
                        <p style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "500"
                        }}>Your session has expired or your account was logged in from another device. You will be logged out in 5 seconds and redirected to the login page.</p>
                        {/* <div className="btns">
                        <button onClick={() => navigateToLogin()}>Yes</button>
                    </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export { axiosInstance, setupAxiosInterceptors, SessionExpiredModal };