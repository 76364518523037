import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../assets/gif/loader.gif";
import buildingIcon from "../../../assets/img/building.png";
import { axiosInstance } from "../../../utils/axiosInstance";
import { APIs } from "../../Config/config";
import { SuccessError } from "../SuccessError";
import "./companyProfile.css"

function Companyprofile(props) {

    let navigate = useNavigate();
    let [companyProfileData, setCompanyProfileData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [msg, setMsg] = useState();
    let [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        getCompanyProfile();
    }, [])

    let handleCloseModal = () => {
        props.setCompanyModal(false);
    }

    const handleErrorPopup = () => {
        setShowError(!showError)
    }

    let handleSuccess = () => {
        setShowSuccess(false);
    }

    const getCompanyProfile = () => {
        setLoading(true);

        let payLoad = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9047",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "companyProfileId": props.getCompanyProfileId.companyId
                    }
                }
            }
        }

        let apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setCompanyProfileData(temp.data.companydetails);
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                }
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.error.message);
                        props.setCompanyModal(false);
                    } else {
                        
                    }
                }
            });

    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            <SuccessError
                errorMsg={errorMsg}
                showError={showError}
                showSuccess={showSuccess}
                msg={msg}
                handleErrorPopup={handleErrorPopup}
                handleSuccess={handleSuccess} />

            <div className="companyProfile_modal">
                <Modal
                    className="companyProfile_modal"
                    show={props.companyModal}
                    onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <h5 style={{ margin: "0" }}>Company Profile</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">
                            <div className="logo_heading">
                                <div className="logo">
                                    {companyProfileData.companyLogoId ? <img src={APIs.downloadImage + companyProfileData.companyLogoId} alt="Company Icon" onError={(e) => e.target.src = buildingIcon}/>
                                    :
                                    <img src={buildingIcon} alt="Company Icon" />}
                                </div>
                                <div className="heading">
                                    <h4>{companyProfileData?.companyName}</h4>
                                    <h5>{companyProfileData?.industry?.industry}</h5>
                                    <h5>{companyProfileData?.address}</h5>
                                    <a href={companyProfileData?.website} target="_blank">{companyProfileData?.website}</a>
                                </div>
                            </div>
                            <div className="about_company">
                                <h4>About {companyProfileData?.companyName}</h4>
                                <hr></hr>
                                <p>
                                    <span dangerouslySetInnerHTML={{
                                        __html: companyProfileData.aboutCompany
                                    }}></span>
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default Companyprofile;