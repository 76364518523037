import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import "./changePassword.css";
import IconLock from "../../../assets/icons/icon-lock.svg";
import eye_black from "../../../assets/img/register/eye-black.svg";
import eye_off from "../../../assets/img/register/eye-off.svg";
import { APIs } from "../../../shared/Config/config.js";
import axios from "axios";
import { SuccessError } from "../../Popup/SuccessError";
import LoadingIcon from "../../../assets/gif/loader.gif";
import { axiosInstance } from "../../../utils/axiosInstance";

const ChangePassword = ({ isAssessor }) => {
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false
  });
  const [showPasswordInput, setShowPasswordInput] = useState("");
  const [password, setPassword] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordMatchErr, setPasswordMatchErr] = useState("");

  useEffect(() => {
    checkPassword();
  }, [password.confirmPass]);

  const checkPassword = () => {
    if (password.confirmPass !== undefined) {
      if (password.newPass !== password.confirmPass) {
        setPasswordMatchErr("Password does not match")
      } else {
        setPasswordMatchErr("")
      }
    }
  }

  const handleSuccess = () => {
    setShowSuccess(!showSuccess);
  };

  const handleErrorPopup = () => {
    setShowError(!showError);
  };

  const handleShowPassword = (name) => {
    if (name === "new") {
      setShowPassword({
        ...showPassword,
        new: !showPassword.new
      })
    } else if (name === "current") {
      setShowPassword({
        ...showPassword,
        current: !showPassword.current
      })
    } else if (name === "confirm") {
      setShowPassword({
        ...showPassword,
        confirm: !showPassword.confirm
      })
    }

    // setShowPassword(!showPassword);
    // setShowPasswordInput(name);
  };

  const passwordInputHandler = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const changePasswordHandler = async () => {
    setLoading(true);
    const json = {
      json: {
        request: {
          servicetype: 4,
          functiontype: 4011,
          session: {
            sessionid: sessionStorage.getItem("sessionid"),
          },
          data: {
            password: password.currentPass,
            newPassword: password.newPass,
            confirmPassword: password.confirmPass,
          },
        },
      },
    };
    let apiUrl =
      APIs.employerProfileApi + encodeURIComponent(JSON.stringify(json));
    await axiosInstance
      .post(apiUrl)
      .then((result) => {
        if (result.status >= 200 && result.status < 300) {
          let temp = result.data.json.response;
          if (temp.statuscode === "0") {
            handleSuccess();
            setMsg(temp.statusmessage);
            setPassword({ currentPass: "", newPass: "", confirmPass: "" })
            setLoading(false);
          } else {
            handleErrorPopup();
            setErrorMsg(temp.statusmessage);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Change password error");
      });
  };

  const changeAssessorPassword = async () => {
    setLoading(true);
    const json = {
      json: {
        request: {
          servicetype: 9,
          functiontype: 9030,
          session: {
            sessionid: sessionStorage.getItem("sessionid"),
          },
          data: {
            password: password.currentPass,
            newPassword: password.newPass,
            confirmPassword: password.confirmPass,
          },
        },
      },
    };
    let apiUrl =
      APIs.assessorAPI + encodeURIComponent(JSON.stringify(json));
    await axiosInstance
      .post(apiUrl)
      .then((result) => {
        if (result.status >= 200 && result.status < 300) {
          console.log(result, "Result ")
          let temp = result.data.json.response;
          if (temp.statuscode === "0") {
            handleSuccess();
            setMsg(temp.statusmessage);
            setPassword({ currentPass: "", newPass: "", confirmPass: "" })
            setLoading(false);
          } else {
            handleErrorPopup();
            setErrorMsg(temp.statusmessage);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        handleErrorPopup();
        setErrorMsg(error.response.data.json.response.statusmessage);
        setLoading(false);
        console.log(error, "Change password error");
      });
  };
  console.log(showPassword, "showPassword");

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <div className="spin">
            <img src={LoadingIcon} alt="loading-icon" />
          </div>
        </div>
      )}
      <SuccessError
        showSuccess={showSuccess}
        showError={showError}
        msg={msg}
        errorMsg={errorMsg}
        handleSuccess={handleSuccess}
        handleErrorPopup={handleErrorPopup}
      />
      <div className="changePassword_main_container">
        <h6 className="font12">
          Update your password to enhance acocunt protection and keep your
          information safe
        </h6>
        <div className="col-12 pt-2 d-flex justify-content-center">
          <div className="col-5 chng-password-box">
            <div className="d-flex">
              <span>
                <img src={IconLock} height={18} alt="lock-icon" />
              </span>
              <h6 className="f-12-f-500 p-1">{"  "}Change Password</h6>
            </div>
            <Form>
              <Form.Group controlId="currentPassword">
                <Form.Label className="f-12-f-500">
                  Please enter your existing password
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type={
                      showPassword.current
                        ? "text"
                        : "password"
                    }
                    placeholder="Enter your current password"
                    className="font12"
                    name="currentPass"
                    value={password.currentPass}
                    onChange={(e) => passwordInputHandler(e)}
                  />
                  <InputGroup.Text onClick={() => handleShowPassword("current")} style={{ cursor: "pointer" }}>
                    {showPassword.current ? (
                      <img src={eye_black} height={18} alt="eye-black" />
                    ) : (
                      <img src={eye_off} height={18} alt="eye-off" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mt-2" controlId="newPassword">
                <Form.Label className="f-12-f-500">
                  Create a new password
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type={
                      showPassword.new ? "text"
                        : "password"
                    }
                    placeholder="Enter your new password"
                    className="font12"
                    name="newPass"
                    value={password.newPass}
                    onChange={(e) => passwordInputHandler(e)}
                  />
                  <InputGroup.Text onClick={() => handleShowPassword("new")} style={{ cursor: "pointer" }}>
                    {showPassword.new ? (
                      <img src={eye_black} height={18} alt="eye-black" />
                    ) : (
                      <img src={eye_off} height={18} alt="eye-off" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mt-2" controlId="confirmPassword">
                <Form.Label className="f-12-f-500">
                  Re-enter your new password to confirm
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type={
                      showPassword.confirm ? "text"
                        : "password"
                    }
                    placeholder="Confirm your new password"
                    className="font12"
                    name="confirmPass"
                    value={password.confirmPass}
                    onChange={(e) => passwordInputHandler(e)}
                  />
                  <InputGroup.Text onClick={() => handleShowPassword("confirm")} style={{ cursor: "pointer" }}>
                    {showPassword.confirm ? (
                      <img src={eye_black} height={18} alt="eye-black" />
                    ) : (
                      <img src={eye_off} height={18} alt="eye-off" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <label style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{passwordMatchErr}</label>
              </Form.Group>
              <Form.Group className="d-flex justify-content-center">
                <Button
                  className="mt-3 post-button"
                  onClick={isAssessor ? changeAssessorPassword : changePasswordHandler}
                  disabled={password.currentPass === "" || password.newPass === "" || password.confirmPass === "" ? true : false}
                >
                  CHANGE PASSWORD
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
