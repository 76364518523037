// import { configureStore } from "@reduxjs/toolkit";

// import homeSlice from "./homeSlice";

// export default configureStore({
//     reducer: {
//         home: homeSlice,
//     },
// });

import { configureStore } from "@reduxjs/toolkit";
import homeSlice from "./homeSlice";

// ... other imports ...

// Load the state from localStorage, if available
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};

const store = configureStore({
  reducer: {
    home: homeSlice,
    // other reducers...
  },
  preloadedState: persistedState, // Set the initial state from localStorage
});

// Subscribe to store changes and save the state to localStorage
store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export default store;
