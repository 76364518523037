import moment from "moment";
import React, { useState } from "react";
import FeedbackIcon from "../../../../assets/icons/Icon-feedback.png";
import NoData from "../../../../assets/icons/nodata.png"
import "../../InterviewSchedule/InterviewSchedule.css"
import LoadingIcon from "../../../../assets/gif/loader.gif";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function PartnerAssessment(props) {
    let navigate = useNavigate();
    let [loading, setLoading] = useState(false);

    let navigateToInterviewPage = (data) => {
        navigate('/InterviewSchedule', { state: { canData: data, canditionVal:true } });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            <div
                className="col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-white-pagi p-2"
                style={{ height: "auto" }}>
                {props.viewInvitationData <= 0 ?
                    <div className="d-flex justify-content-center pt-5">
                        <img src={NoData} height={150} alt="no-data found" />
                    </div>
                    :
                    <div className={props.viewInvitationData?.length > 8 ? "scheduled_interview_table_container partner_assessment" : "scheduled_interview_table_container_2 partner_assessment"}>
                        {props.viewInvitationData?.map((item, index) => (
                            <div className="container-fluid scheduled_interview_table" key={index}>
                                <div className="row">
                                    <div className="col-lg-10 scheduled_interview_table_content_1">
                                        <div className="row">
                                            <div className={item?.callStatus === "Completed" ? "col-lg-2 interview_status_com" : "col-lg-2 interview_status"}>
                                                <span>{item.callStatus}</span>
                                            </div>
                                            <div className="col-lg-2 interview_name" onClick={() => navigateToInterviewPage(item)}>
                                                <label>Candidate</label>
                                                <span>{item.firstName}{" "}{item.lastName}</span>
                                            </div>
                                            <div className="col-lg-2 interview_company">
                                                <label>Hiring Status</label>
                                                <span>{item.state}</span>
                                            </div>
                                            <div className="col-lg-3 interview_job">
                                                <label>Job Title</label>
                                                <span>{item.jobTitle}</span>
                                            </div>
                                            <div className="col-lg-3 interview_on">
                                                <label>Interview on</label>
                                                <span>{moment.utc(item.scheduledTime).local().format('DD-MMMM-YYYY, HH:mm')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 scheduled_interview_table_content_2">
                                        {/* <div className="interview_btn">
                                    {item?.viewFeedBack ?
                                        <div className="table_buttons">
                                            <img className="cur" src={FeedbackIcon} onClick={() => FeedbackHandler(item)} alt="Schedule-icon" />
                                            <label style={{ cursor: "pointer" }} onClick={() => FeedbackHandler(item)}>View Feedback</label>
                                        </div>
                                        :
                                        <img className="cur" src={FeedbackIcon} style={{ opacity: "0.2", display: "none" }} alt="Schedule-icon" />
                                    }

                                    {item?.reschedule ?
                                        <div className="table_buttons">
                                            <img className="cur" src={ScheduleIcon} onClick={() => rescheduleHandler(item)} alt="Schedule-icon" />
                                            <label style={{ cursor: "pointer" }} onClick={() => rescheduleHandler(item)}>Change Schedule</label>
                                        </div>
                                        :
                                        <img className="" src={ScheduleIcon} style={{ opacity: "0.2", display: "none" }} alt="Schedule-icon" />
                                    }
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}

                        {(props.viewInvitationData?.length == 0 || props.pageCount <= 1) ? "" :
                            <div style={{ marginTop: "22px" }}>
                                <ReactPaginate
                                    previousLabel={<i className="bi-arrow-left"></i>}
                                    nextLabel={<i className="bi-arrow-right"></i>}
                                    pageCount={props.pageCount}
                                    onPageChange={props.handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={props.currentPage}
                                    previousClassName={props.currentPage === 0 ? "hidden" : ""}
                                    nextClassName={props.currentPage === props.pageCount - 1 ? "hidden" : ""}
                                />
                            </div>}
                    </div>}
            </div>
        </>
    );
}

export default PartnerAssessment;