import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SuccessError } from "../../../../shared/Popup/SuccessError";
import { Modal } from "react-bootstrap";
import LoadingIcon from "../../../../assets/gif/loader.gif";
import userIcon from '../../../../assets/icons/user-icon.png';
import ReactPaginate from "react-paginate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PartnerAssessment from "./partnerAssessment";
import { APIs } from "../../../../shared/Config/config";
import moment from "moment";
import NoData from "../../../../assets/icons/nodata.png"
import axios from "axios";
import Companyprofile from "../../../../shared/Popup/CompanyProfile/companyProfile";
import { axiosInstance } from "../../../../utils/axiosInstance";

function Confirmed(props) {
    let navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [msg, setMsg] = useState();
    let [showSuccess, setShowSuccess] = useState(false);
    let [showConfirmationModal, setShowConfirmationModal] = useState(false);
    let [getConfirmationData, setGetConfirmationData] = useState({});
    let [showPartnership, setShowPartnership] = useState(false);
    let [innerTabs, setInnerTabs] = useState("assessments");
    let [viewInvitationData, setViewInvitationData] = useState();
    let [companyModal, setCompanyModal] = useState(false);
    let [getCompanyProfileId, setGetCompanyProfileId] = useState();
    let [companyId, setCompanyId] = useState();
    let [pageCount, setPageCount] = useState(0);
    let [currentPage, setCurrentPage] = useState(0);

    const handleErrorPopup = () => {
        setShowError(!showError)
    }

    let handleSuccess = () => {
        setShowSuccess(false);
    }

    let handleCloseModal = () => {
        setShowConfirmationModal(false);
    }

    let getConfirmationModal = (data, type) => {
        setGetConfirmationData({ data, type });
        setShowConfirmationModal(true);
    }

    let viewPartnerShip = (companyId) => {
        setShowPartnership(!showPartnership);
        setCompanyId(companyId);
        viewInvitation(0, companyId.companyId); // interview list API
    }

    const handleInnerTabs = (e) => {
        setInnerTabs(e);
    }

    let viewCompanyModal = (item) => {
        setGetCompanyProfileId(item);
        setCompanyModal(true);
    }

    let handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        viewInvitation(selectedPage, companyId.companyId);
    }

    const updateInvitation = (state, id) => {
        setLoading(true);
        let payLoad = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9044",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "id": id,
                        "state": state,
                        "rejectReason": ""
                    }
                }
            }
        }

        let apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setShowSuccess(true);
                    setMsg(state === 4 ? "You Have Successfully Closed Partnership" : "You Have Successfully Accept Partnership");
                    setShowConfirmationModal(false);
                    props.InvitaionList(0, props.stateInt, "")
                    setShowPartnership(false);
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                    setShowConfirmationModal(false);
                    props.InvitaionList(0, props.stateInt, "")
                }
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.error.message);
                        setShowConfirmationModal(false);
                    } else {
                        
                    }
                }
            });
    }

    let per_page = 10;
    let offset;
    const viewInvitation = (currentPageCount, compId) => {
        setLoading(true);
        setCurrentPage(currentPageCount)
        offset = currentPageCount * per_page;

        let payLoad = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9041",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "companyId": compId
                    },
                    "sort": {
                        "sortby": "ScheduleTime",
                        "orderby": "DESC"
                    },
                    "records": {
                        "offset": offset,
                        "limit": per_page
                    }
                }
            }
        }


        let apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setViewInvitationData(temp.data.scheduledList);
                    setPageCount(Math.ceil(temp.data.count / per_page));
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                }
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.error.message);
                        setShowConfirmationModal(false);
                    } else {
                        
                    }
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            <SuccessError
                errorMsg={errorMsg}
                showError={showError}
                showSuccess={showSuccess}
                msg={msg}
                handleErrorPopup={handleErrorPopup}
                handleSuccess={handleSuccess} />

            {/* --------> view company modal <--------- */}
            {
                companyModal &&
                <Companyprofile
                    companyModal={companyModal}
                    setCompanyModal={setCompanyModal}
                    getCompanyProfileId={getCompanyProfileId} />
            }

            {
                !showPartnership ?
                    // -----------------=====> Confirmed PartnerShip <======------------------
                    <div className="pending_partnerShip">
                        <>
                            <div className="key_search">
                                <div className="vertical_border"></div>
                                <i className="fa fa-search icon-invite"></i>
                                <input
                                    className="form-control input-field-invite"
                                    type="text"
                                    value={props.searchVal}
                                    onChange={(e) => props.InvitaionList(0, props.stateInt, e.target.value)}
                                    placeholder="Search company name"
                                ></input>
                            </div>
                            {props.getInvitaionList.length > 0 ?
                                <div className="main_cards_content">
                                    <div className="cards">
                                        {
                                            props.getInvitaionList?.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="header">
                                                        {item.companyLogoId <= 0 ?
                                                            <img src={userIcon} alt="company logo" style={{
                                                                width: "90px",
                                                                borderRadius: "50%",
                                                                marginRight: "14px"
                                                            }} />
                                                            :
                                                            <img src={APIs.downloadImage + item.companyLogoId} alt="Company Icon"
                                                                onError={(e) => e.target.src = userIcon} style={{
                                                                    width: "90px",
                                                                    borderRadius: "50%",
                                                                    marginRight: "14px"
                                                                }} />
                                                        }
                                                        <div className="card_content">
                                                            <p>Received on {moment.utc(item?.createdTime).local().format("DD MMM YYYY")}</p>
                                                            <label onClick={() => viewCompanyModal(item)}>{item?.companyName}</label>
                                                            <p><b>Charge per interview</b> : {item?.costnegotiated}</p>
                                                            <p style={{ textAlign: "justify" }} className={item?.message?.length <= 90 ? "msg" : "msgOver"}>{item?.message}</p>
                                                        </div>
                                                    </div>
                                                    <div className="horizontal_border"></div>
                                                    <div className="footer">
                                                        <div className="decline" onClick={() => getConfirmationModal(item, "close")}>
                                                            <i className="bi bi-x-circle"></i>
                                                            <label>Close Partnership</label>
                                                        </div>
                                                        <div className="vertical_border"></div>
                                                        <div className="accept view_partnerShip" onClick={() => viewPartnerShip(item)}>
                                                            <i className="bi bi-justify"></i>
                                                            <label>View Partnership</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {/* <div className="card">
                                <div className="header">
                                    <img src={userIcon} alt="company logo" style={{
                                        width: "100px",
                                        borderRadius: "50%",
                                        marginRight: "30px"
                                    }} />
                                    <div className="card_content">
                                        <p>Received on </p>
                                        <label>sdkljnsf</label>
                                        <div><a>www.abccompany.com</a></div>
                                    </div>
                                </div>
                                <div className="horizontal_border"></div>
                                <div className="footer">
                                    <div className="decline" onClick={() => getConfirmationModal("item", "close")}>
                                        <i className="bi bi-x-circle"></i>
                                        <label>Close Partnership</label>
                                    </div>
                                    <div className="vertical_border"></div>
                                    <div className="accept" onClick={() => viewPartnerShip()}>
                                        <i className="bi bi-justify"></i>
                                        <label>View Partnership</label>
                                    </div>
                                </div>
                            </div> */}
                                    </div>
                                    {props.pageCount > 1 &&
                                        <div className="partnerShip_pagination">
                                            <ReactPaginate
                                                previousLabel={<i className="bi-arrow-left"></i>}
                                                nextLabel={<i className="bi-arrow-right"></i>}
                                                pageCount={props.pageCount}
                                                onPageChange={props.handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                forcePage={props.currentPage}
                                                previousClassName={props.currentPage == 0 ? "hidden" : ""}
                                                nextClassName={props.currentPage == props.pageCount - 1 ? "hidden" : ""}
                                            />
                                        </div>
                                    }
                                </div>
                                :
                                <div className="no_data_found">
                                    <div className="d-flex justify-content-center">
                                        <img src={NoData} height={150} alt="no-data found" />
                                    </div>
                                </div>
                            }
                        </>
                    </div>
                    :
                    // -----------------=====> View PartnerShip <======------------------
                    <div className="pending_partnerShip" style={{ overflowY: "scroll" }}>
                        <div className="card companyCard">
                            <div className="back_btn">
                                <label onClick={() => viewPartnerShip()}>
                                    <i className="bi bi-chevron-left"></i>
                                    <span>Back to list</span>
                                </label>
                            </div>
                            <div className="main_header">
                                <div className="header">
                                    {companyId?.companyLogoId <= 0 ?
                                        <img src={userIcon} alt="company logo" style={{
                                            width: "90px",
                                            borderRadius: "50%",
                                            marginRight: "14px"
                                        }} />
                                        :
                                        <img src={APIs.downloadImage + companyId.companyLogoId} alt="Company Icon"
                                            onError={(e) => e.target.src = userIcon} style={{
                                                width: "90px",
                                                borderRadius: "50%",
                                                marginRight: "14px"
                                            }} />
                                    }
                                    <div className="card_content">
                                        <p>Received on {moment.utc(companyId?.createdTime).local().format("DD MMM YYYY")}</p>
                                        <label onClick={() => viewCompanyModal(companyId)}>{companyId?.companyName}</label>
                                        <p><b>Charge per interview</b> : {companyId?.costnegotiated}</p>
                                        <p style={{ textAlign: "justify", color: "black" }} className={companyId?.message?.length <= 90 ? "msg" : "msgOver"}>{companyId?.message}</p>
                                    </div>
                                </div>
                                <div className="closePartner_btn">
                                    <label onClick={() => getConfirmationModal(companyId, "close")} style={{ width: "110px" }}>
                                        <i className="bi bi-x-circle"></i>
                                        <span>Close Partnership</span>
                                    </label>
                                </div>
                            </div>
                            <div className="horizontal_border"></div>
                            <div className="footer">
                                <div className="decline" style={{ cursor: "default" }}>
                                    <div className="assessment_number">
                                        <label style={{ cursor: "default" }}>No. of Assessments</label>
                                        <label style={{ cursor: "default" }}>{companyId.noAssessments}</label>
                                    </div>
                                </div>
                                <div className="vertical_border"></div>
                                <div className="accept" style={{ cursor: "default" }}>
                                    <div className="assessment_number">
                                        <label style={{ cursor: "default" }}>Earnings</label>
                                        <label style={{ cursor: "default" }}>{companyId.earnings}</label>
                                    </div>
                                </div>
                                <div className="vertical_border"></div>
                                <div className="accept" style={{ cursor: "default" }}>
                                    <div className="assessment_number">
                                        <label style={{ cursor: "default" }}>Encashed</label>
                                        <label style={{ cursor: "default" }}>{companyId.encashed ?? 0}</label>
                                    </div>
                                </div>
                                <div className="vertical_border"></div>
                                <div className="accept" style={{ cursor: "default" }}>
                                    <div className="assessment_number">
                                        <label style={{ cursor: "default" }}>Balance</label>
                                        <label style={{ cursor: "default" }}>{companyId.balance}</label>
                                    </div>
                                </div>
                                <div className="vertical_border"></div>
                                <div className="accept">
                                    <div className="assessment_number">
                                        {/* <label>No. of Assessments</label>
                                        <label>0</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ------------------====> Inner Tabs <=====----------------- */}
                        <div className="innerTabs">
                            <Tabs
                                id="justify-tab-example"
                                activeKey={innerTabs}
                                onSelect={(e) => handleInnerTabs(e)}
                            >
                                <Tab eventKey="assessments" title="ASSESSMENTS">
                                    {innerTabs === "assessments" &&
                                        <PartnerAssessment
                                            viewInvitationData={viewInvitationData}
                                            handlePageClick={handlePageClick}
                                            currentPage={currentPage}
                                            pageCount={pageCount} />
                                    }
                                </Tab>
                                {/* <Tab eventKey="evaluation" title="EVALUATIONS">

                                </Tab> */}
                            </Tabs>
                        </div>
                    </div>
            }
            <Modal
                className="confirmation_modal"
                show={showConfirmationModal}
                onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <div className="header">
                        <h4>Confirmation {getConfirmationData?.type === "close" ? <i className="bi bi-emoji-frown"></i> : <i className="bi bi-emoji-smile"></i>}</h4>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        {getConfirmationData?.type === "close" ?
                            <p>Are you sure you want to <span style={{ color: "red", fontWeight: "500" }}>close</span> the partnership with <b>{getConfirmationData?.data?.companyName}</b>?</p>
                            :
                            <p>Are you sure you want to <span style={{ color: "green", fontWeight: "500" }}>accept</span> the partnership with <b>{getConfirmationData?.data?.companyName}</b> ?</p>
                        }
                        <div className="btns">
                            {getConfirmationData?.type === "close" ?
                                <button onClick={() => updateInvitation(4, getConfirmationData?.data?.id)}>Yes</button>
                                :
                                <button onClick={() => updateInvitation(2, getConfirmationData?.data?.id)}>Yes</button>
                            }
                            <button onClick={() => handleCloseModal()}>No</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Confirmed;