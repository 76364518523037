import React, { useEffect, useState } from "react";
import "./interviewAvailability.css";
// import Button from "react-bootstrap/Button";
import { Form, Col, Button, Row } from "react-bootstrap";
import { APIs } from "../../../shared/Config/config.js";
import axios from "axios";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { SuccessError } from "../../Popup/SuccessError";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../assets/gif/loader.gif";
import { axiosInstance } from "../../../utils/axiosInstance";

const InterviewAvailability = (props) => {
  const navigate = useNavigate();
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  let [getAvailability, setGetAvailability] = useState();
  let [selectedTimezone, setSelectedTimezone] = useState();
  let [selectedSlot, setSlectedSlot] = useState();
  let [timeSlot, setTimeSlot] = useState();
  let [checkSlotDays, setCheckSlotDays] = useState([]);
  let [startDateTime, setStartDateTime] = useState([]);
  let [endDateTime, setEndDateTime] = useState([]);
  let [showError, setShowError] = useState(false);
  let [errorMsg, setErrorMsg] = useState();
  let [msg, setMsg] = useState();
  let [showSuccess, setShowSuccess] = useState(false);
  let [disableBtn, setDisableBtn] = useState(false);
  let [showDisableBtnMsg, setShowDisableBtnMsg] = useState(false);
  let [loading, setLoading] = useState(false);
  let getIsAdminKey = sessionStorage.getItem("isAdmin");

  let timezoneList = ["Asia/Kolkata", "America/New_York"]

  useEffect(() => {
    getAssessorAvailability();
    getSlotTime();
  }, []);

  let onChangeTimezone = (e) => {
    setSelectedTimezone({
      value: e.value,
      label: e.label
    });
    setDisableBtn(true);
  }

  let onChangeSlot = (e) => {
    setSlectedSlot({
      value: e.value,
      label: e.label
    });
    setDisableBtn(true);
  }

  let onChangeSlotDays = (e, ind) => {
    const newCheckSlotDays = [...checkSlotDays];
    newCheckSlotDays[ind].working = e.target.checked;
    setCheckSlotDays(newCheckSlotDays);
    setDisableBtn(true);
  }

  let onChangeStartTime = (date, ind) => {
    const newCheckSlotDays = [...checkSlotDays];
    let tempTime = moment(date).format("HH:mm:SS");
    if (newCheckSlotDays[ind].startTime == tempTime) {
      newCheckSlotDays[ind].startTime = newCheckSlotDays[ind].startTime;
    } else {
      newCheckSlotDays[ind].startTime = tempTime
    }

    if (newCheckSlotDays[ind].startTime >= newCheckSlotDays[ind].endTime) {
      newCheckSlotDays[ind].endTime = "";
    }
    setStartDateTime(newCheckSlotDays[ind].startTime);
    setCheckSlotDays(newCheckSlotDays);
    setShowDisableBtnMsg(true);
  }

  let onChangeEndTime = (date, ind) => {
    const newCheckSlotDays = [...checkSlotDays];
    let tempTime = date === "" ? date : moment(date).format("HH:mm:SS");
    if (newCheckSlotDays[ind].endTime == tempTime) {
      newCheckSlotDays[ind].endTime = newCheckSlotDays[ind].endTime;
    } else {
      newCheckSlotDays[ind].endTime = tempTime;
    }

    let invalidTimeItems = newCheckSlotDays?.filter(item => item?.endTime == "")
    if (invalidTimeItems.length > 0) {
      setShowDisableBtnMsg(true);
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
      setShowDisableBtnMsg(false);
    }

    setEndDateTime(newCheckSlotDays[ind].endTime);
    setCheckSlotDays(newCheckSlotDays);
  }

  let handleErrorPopup = () => {
    setShowError(false);
  }

  let handleSuccess = () => {
    setShowSuccess(false);
  }

  const calculateDisabledTimes = (startTime) => {
    if (!startTime) {
      return [];
    }

    const disabledTime = new Date(`1970-01-14T${startTime}`);
    return [disabledTime];
  };

  // -----------------========> ASSESSOR <==========----------------------
  const getAssessorAvailability = async () => {
    setLoading(true);
    let payLoad = {
      "servicetype": 81,
      "functiontype": 11,
      "sessionid": sessionStorage.getItem("sessionid"),
      "data": {

      }
    }

    let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));
    await axiosInstance.post(apiUrl)
      .then((result) => {
        setLoading(false);
        let res = result?.data?.data;
        setGetAvailability(res.availability_details);
        let arr = [];
        res.availability_details.map((itm) => (
          arr.push({
            weekdayId: itm.weekdayId,
            working: itm.working,
            startTime: itm?.startTime,
            endTime: itm?.endTime
          })
        ));
        setCheckSlotDays(arr);
        setSelectedTimezone({
          value: res.timezone,
          label: res.timezone
        });
        if (!getIsAdminKey) {
          sessionStorage.setItem("timeZone", res.timezone);
        }
        setSlectedSlot({
          value: res?.slotTimeId,
          label: res?.slotTime
        });
      }).catch((err) => {
        setLoading(false);
        if (err) {
          if (err.response.status >= 400 && err.response.status < 500) {
            setShowError(true);
            setErrorMsg(err.response.data.error.message)
          } else {
            
          }
        }
      })

  }

  // --------------> Assessor Get Time Slot <-------------
  const getSlotTime = () => {
    setLoading(true);
    let payLoad = {
      "servicetype": 81,
      "functiontype": 13,
      "sessionid": sessionStorage.getItem("sessionid"),
    }

    let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));
    axiosInstance.post(apiUrl)
      .then((result) => {
        setLoading(false);
        let res = result.data.data;
        let arr = [];
        arr.push({
          value: res.Id,
          value: res.slotTime
        })
        setSlectedSlot(arr);
        setTimeSlot(res.slot_details)
      }).catch((err) => {
        setLoading(false);
        if (err) {
          if (err.response.status >= 400 && err.response.status < 500) {
            setShowError(true);
            setErrorMsg(err.response.data.error.message)
          } else {
            
          }
        }
      })
  }

  // -------------------> Update Assessor Availability <-------------------
  let updateAssessorAvailability = () => {
    setLoading(true);
    let payLoad = {
      "servicetype": 81,
      "functiontype": 12,
      "sessionid": sessionStorage.getItem("sessionid"),
      "data": {
        "slottimeId": selectedSlot.value,
        "timezone": selectedTimezone.value,
        "availability": checkSlotDays
      }
    }

    let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));
    axiosInstance.post(apiUrl)
      .then((result) => {
        setLoading(false);
        let res = result.data.data;
        setShowSuccess(true);
        setMsg(res.message);
        setDisableBtn(false);
        getAssessorAvailability();
      }).catch((err) => {
        if (err) {
          setLoading(false);
          if (err.response.status >= 400 && err.response.status < 500) {
            setShowError(true);
            setErrorMsg(err.response.data.error.message)
          } else {
            
          }
        }
      })
  }

  console.log(selectedSlot);

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <div className="spin">
            <img src={LoadingIcon} />
          </div>
        </div>
      )}
      < SuccessError
        errorMsg={errorMsg}
        showError={showError}
        showSuccess={showSuccess}
        msg={msg}
        handleErrorPopup={handleErrorPopup}
        handleSuccess={handleSuccess} />
      <div className="interview_availability_main_container">
        <div className="interview_availability d-flex align-items-center col-12">
          <div className="col-9 title">
            <h4 className="mb-1">Set Interview Availability Settings</h4>
            <h6 className="f12-clr-light">
              Configure interview hours and time interval for interviews
            </h6>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <Button className=" button-save-hour font12" type="submit" onClick={() => updateAssessorAvailability()} disabled={!disableBtn}>
              SAVE SETTINGS
            </Button>
          </div>
        </div>

        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-3">
              <h4 className="mb-1 font12">Time Zone</h4>
              <h6 className="f12-clr-light">Set the timezone</h6>
            </div>
            <div className="col-9">
              <div style={{ width: "50%" }}>
                <Select
                  isMulti={false}
                  value={selectedTimezone}
                  onChange={(e) => onChangeTimezone(e)}
                  placeholder={"Time Zone"}
                  options={timezoneList.map((itm) => {
                    return { value: itm, label: itm }
                  })}
                  // isDisabled
                  className="font12"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-3">
              <h4 className="mb-1 font12">Slot Interval</h4>
              <h6 className="f12-clr-light">
                Configure time interval for each interview
              </h6>
            </div>
            <div className="col-9 slots_time">
              <div style={{ width: "50%" }}>
                <Select
                  isMulti={false}
                  value={selectedSlot}
                  onChange={(e) => onChangeSlot(e)}
                  placeholder={"select slot"}
                  options={timeSlot?.map((itm) => {
                    return { value: itm.Id, label: itm.slotTime }
                  })}
                  className="font12"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container-fluid mt-3 Assessot_configure">
          <div className="row">
            <div className="col-3">
              <h4 className="mb-1 font12">Slot Interval</h4>
              <h6 className="f12-clr-light">
                Configure time interval for each interview
              </h6>
            </div>
            <div className="col-9">
              <Form>
                {checkSlotDays?.map((item, index) => (
                  <Form.Group
                    as={Row}
                    controlId="formSwitchAndSelect"
                    className="mb-3 assessor_availability"
                    key={index}
                  >
                    <Col sm={3}>
                      <Form.Check className="font12 custom_switch" controlId="formSwitchAndSelect" id="swith_1" type="switch" name="toggle"
                        label={
                          item.weekdayId == 1 ?
                            "Sunday" : item.weekdayId == 2 ? "Monday" : item.weekdayId == 3 ? "Tuesday" : item.weekdayId == 4 ? "Wednesday" :
                              item.weekdayId == 5 ? "Thursday" : item.weekdayId == 6 ? "Friday" : item.weekdayId == 7 ? "Saturday" : null
                        } onChange={(e) => onChangeSlotDays(e, index)}
                        value={checkSlotDays} checked={item.working} />
                    </Col>
                    {
                      item.working ?
                        <>
                          <Col sm={4} className="font12 fromTime_picker">
                            <div className="FormTime_label">
                              <label className="font12">From</label>
                            </div>
                            <DatePicker
                              // selected={startDateTime.length == 0 ? new Date(moment.utc(`1970-01-14T${item?.startTime}`).local()) : new Date(`1970-01-14T${startDateTime}`)}
                              selected={new Date(`1970-01-14T${item?.startTime}`)}
                              onChange={(date) => onChangeStartTime(date, index)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={selectedSlot?.label}
                              timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                            // minTime={new Date(1970, 0, 1, 0, 0)}
                            // maxTime={new Date(1970, 0, 1, new Date(`1970-01-14T${item?.endTime}`).getHours(), new Date(`1970-01-14T${item?.endTime}`).getMinutes())}
                            // excludeTimes={calculateDisabledTimes(item?.endTime)}
                            />
                            {/* <i className="bi-chevron-down"></i> */}
                          </Col>
                          {/* <Col sm={2}></Col> */}
                          <Col sm={4} className="font12 toTime_picker">
                            <div className="FormTime_label">
                              <label className="font12">To</label>
                            </div>
                            <DatePicker
                              // selected={endDateTime.length == 0 ? new Date(moment.utc(`1970-01-14T${item?.endTime}`).local()) : endDateTime}
                              selected={item?.endTime === "" ? null : new Date(`1970-01-14T${item?.endTime}`)}
                              onChange={(date) => onChangeEndTime(date, index)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={selectedSlot?.label}
                              // timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              // minDate={new Date()}
                              minTime={new Date(new Date().setHours(new Date(`1970-01-14T${item?.startTime}`).getHours(), new Date(`1970-01-14T${item?.startTime}`).getMinutes(), 0, 0))}
                              maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                              excludeTimes={calculateDisabledTimes(item?.startTime)}
                              placeholderText="HH:mm"
                            />
                            {/* <i className="bi-chevron-down"></i> */}
                          </Col>
                          {/* {showDisableBtnMsg && <label>date should be greater than from date</label>} */}
                        </>
                        :
                        <Col sm={4} className="disabledDate font12">
                          <p>Closed</p>
                        </Col>
                    }
                  </Form.Group>
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewAvailability;
