import React, { useEffect, useState } from "react";
import "./integrations.css"
import googleCalendar from "../../../../assets/img/google-calendar.png"
import trash from "../../../../assets/img/Icon feather-trash-2.svg"
import { useNavigate } from "react-router-dom";
import config from "../../../../shared/Config/config.js";
import axios from "axios";
import { SuccessError } from "../../../../shared/Popup/SuccessError";
import { Modal } from "react-bootstrap";
import LoadingIcon from "../../../../assets/gif/loader.gif";
import { APIs, credentials } from "../../../../shared/Config/config";
import { axiosInstance } from "../../../../utils/axiosInstance";

function Integration(props) {
    const navigate = useNavigate();
    let [changeUi, setChangeUi] = useState();
    let [syncedData, setSyncedData] = useState();
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [msg, setMsg] = useState();
    let [showSuccess, setShowSuccess] = useState(false);
    let [showConfirmationModal, setShowConfirmationModal] = useState(false);
    let [loading, setLoading] = useState(false);

    // const redirect_url = "http://localhost:4000/assessorSettings"
    const redirect_url = `${APIs.redirect_url}/assessorSettings`

    const handleAuthorize = () => {
        window.location.replace(`https://accounts.google.com/o/oauth2/auth?client_id=${credentials.clientId}&redirect_uri=${redirect_url}&response_type=code&scope=${credentials.scope}&access_type=offline`);

    }

    function getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            // var regexWithEncodedChars = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'g'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    useEffect(() => {
        var code = getParameterByName('code');
        if (code) {
            console.log(code);
            sessionStorage.setItem("assessorSettingTabs", "IntegrationsTab");
            addCalenderSync(code)
            // exchangeAuthorizationCodeForAccessToken(code);
        } else {
            viewCalenderSync();
        }
    }, [])

    let handleErrorPopup = () => {
        setShowError(false);
    }

    let handleSuccess = () => {
        setShowSuccess(false);
    }

    let getConfirmationModal = () => {
        setShowConfirmationModal(true);
        // deleteCalenderSync(syncedData.googleSyncId);
    }

    let handleCloseModal = () => {
        setShowConfirmationModal(false);
    }

    // Are you sure you want to disconnect?

    // function exchangeAuthorizationCodeForAccessToken(code) {
    //     const accessTokenUrl = "https://accounts.google.com/o/oauth2/token";
    //     const data = {
    //         grant_type: "authorization_code",
    //         client_id: "773281784707-ne42ltlk0kpk1eaa4hgs1sref64ktk3n.apps.googleusercontent.com",
    //         client_secret: "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",
    //         code: code,
    //         redirect_uri: "http://localhost:4000/assessorSettings",
    //     };

    //     axiosInstance.post(accessTokenUrl, null, {
    //         params: data,
    //     })
    //         .then((response) => {
    //             let tempAccessToken = response.data;
    //             addCalenderSync(tempAccessToken.refresh_token, code);
    //             getUserInfo(tempAccessToken.access_token);
    //         })
    //         .catch((error) => {
    //             console.error("Error exchanging code for access token:", error);
    //         });
    // }

    // const getUserInfo = async (token) => {

    //     let apiUrl = "https://www.googleapis.com/oauth2/v1/userinfo";

    //     await axiosInstance.get(apiUrl, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         }
    //     })
    //         .then((result) => {
    //             console.log(result)
    //             let temp = result.data;
    //         }).catch((err) => {
    //             if (err) {
    //                 console.log(err, "error");
    //             }
    //         })
    // }

    const addCalenderSync = (code) => {
        setLoading(true);
        let payLoad = {
            "servicetype": 81,
            "functiontype": 8,
            "sessionid": sessionStorage.getItem("sessionid"),
            "data": {
                // "refreshToken": refreshToken,
                "code": code
            }
        }

        let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data
                setSyncedData(temp.data);
                props.setProfileTab("IntegrationsTab");
                setChangeUi(true);
                setShowSuccess(true);
                setMsg("Google Calendar integration is successful");
                navigate("/assessorSettings");
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setChangeUi(false);
                        setShowError(true);
                        setErrorMsg(error.response.data.errors.statusmessage);
                        navigate("/assessorSettings");
                    } else {
                        
                    }
                }
            });
    }

    const viewCalenderSync = () => {
        setLoading(true);
        let payLoad = {
            "servicetype": 81,
            "functiontype": 9,
            "sessionid": sessionStorage.getItem("sessionid"),
            "data": {
            }
        }

        let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data
                setSyncedData(temp.data);
                // props.setProfileTab("IntegrationsTab");
                setChangeUi(true);
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    setChangeUi(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        console.log(error)
                        setShowError(false);
                        setErrorMsg(error.response.data.errors.statusmessage);
                    } else {
                        
                    }
                }
            });
    }

    const deleteCalenderSync = (id) => {
        setLoading(true);
        let payLoad = {
            "servicetype": 81,
            "functiontype": 10,
            "sessionid": sessionStorage.getItem("sessionid"),
            "data": {
                "googleSyncId": id
            }
        }

        let apiUrl = APIs.passThroughAPI + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data
                setChangeUi(false);
                setShowConfirmationModal(false);
                setShowSuccess(true);
                setMsg("you are disconnected from Google Calendar");
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    setChangeUi(true);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.errors.statusmessage);
                    } else {
                        
                    }
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}

            < SuccessError
                errorMsg={errorMsg}
                showError={showError}
                showSuccess={showSuccess}
                msg={msg}
                handleErrorPopup={handleErrorPopup}
                handleSuccess={handleSuccess} />

            <div className="integration">
                <div className="heading">
                    <h4>Manage integrations with external apps</h4>
                </div>
                <div className="content">
                    <img src={googleCalendar} alt="calendar" />
                    <div className="child_content">
                        <h5>Google Calendar</h5>
                        <p>Connect with Google Calendar and add all your events in your</p>
                        <p>Google Calendar</p>
                        {!changeUi ?
                            < button onClick={handleAuthorize}>INTEGRATE</button>
                            :
                            <div className="Integrate_with">
                                <p>Integrated with</p>
                                <div className="synced_data">
                                    <p style={{ margin: "0" }}>{syncedData.emailId}</p>
                                    {/* <p style={{ margin: "0" }}>cvsonar@gmail.com</p> */}
                                    <img src={trash} alt="delete" onClick={() => getConfirmationModal()} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal
                className="confirmation_modal"
                show={showConfirmationModal}
                onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <div className="header">
                        <h4>Confirmation</h4>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        <p>Are you sure you want to disconnect Google Calendar?</p>
                        <div className="btns">
                            <button onClick={() => deleteCalenderSync(syncedData.googleSyncId)}>Yes</button>
                            <button onClick={() => handleCloseModal()}>No</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Integration;