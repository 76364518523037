import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
    name: "home",
    initialState: {
        recruiter: {},
        assessor: {},
        assignedQB: {},
        jobDetailsData: {},
    },
    reducers: {
        getRecruiter: (state, action) => {
            state.recruiter = action.payload;
        },
        getAssessor: (state, action) => {
            state.assessor = action.payload;
        },
        getAssignedQB: (state, action) => {
            state.assignedQB = action.payload;
        },
        getJobDetailsData: (state, action) => {
            state.jobDetailsData = action.jobDetailsData;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getRecruiter, getAssessor, getAssignedQB, getJobDetailsData } = homeSlice.actions;

export default homeSlice.reducer;