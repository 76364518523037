import React, { useState } from "react";
import "../Pending/pending.css"
import userIcon from '../../../../assets/icons/user-icon.png';
import ReactPaginate from "react-paginate";
import { APIs } from "../../../../shared/Config/config.js";
import LoadingIcon from "../../../../assets/gif/loader.gif";
import moment from "moment";
import Companyprofile from "../../../../shared/Popup/CompanyProfile/companyProfile";
import NoData from "../../../../assets/icons/nodata.png"

function Closed(props) {
    let [companyModal, setCompanyModal] = useState(false);
    let [getCompanyProfileId, setGetCompanyProfileId] = useState();

    let viewCompanyModal = (item) => {
        setGetCompanyProfileId(item);
        setCompanyModal(true);
    }

    return (
        <>
            {/* --------> view company modal <--------- */}
            {
                companyModal &&
                <Companyprofile
                    companyModal={companyModal}
                    setCompanyModal={setCompanyModal}
                    getCompanyProfileId={getCompanyProfileId} />
            }
            <div className="pending_partnerShip">
                <>
                    <div className="key_search">
                        <div className="vertical_border"></div>
                        <i className="fa fa-search icon-invite"></i>
                        <input
                            className="form-control input-field-invite"
                            type="text"
                            value={props.searchVal}
                            onChange={(e) => props.InvitaionList(0, props.stateInt, e.target.value)}
                            placeholder="Search company name"
                        ></input>
                    </div>
                    {props.getInvitaionList.length > 0 ?
                        <div className="main_cards_content">
                            <div className="cards">
                                {
                                    props.getInvitaionList?.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="header">
                                                {item.companyLogoId <= 0 ?
                                                    <img src={userIcon} alt="company logo" style={{
                                                        width: "90px",
                                                        borderRadius: "50%",
                                                        marginRight: "14px"
                                                    }} />
                                                    :
                                                    <img src={APIs.downloadImage + item.companyLogoId} alt="Company Icon"
                                                        onError={(e) => e.target.src = userIcon} style={{
                                                            width: "90px",
                                                            borderRadius: "50%",
                                                            marginRight: "14px"
                                                        }} />
                                                }
                                                <div className="card_content">
                                                    <p>Received on {moment.utc(item?.createdTime).local().format("DD MMM YYYY")}</p>
                                                    <label onClick={() => viewCompanyModal(item)}>{item?.companyName}</label>
                                                    <p><b>Charge per interview</b> : {item?.costnegotiated}</p>
                                                    <p style={{ textAlign: "justify" }} className={item?.message?.length <= 90 ? "msg" : "msgOver"}>{item?.message}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {props.pageCount > 1 &&
                                    <div className="partnerShip_pagination">
                                        <ReactPaginate
                                            previousLabel={<i className="bi-arrow-left"></i>}
                                            nextLabel={<i className="bi-arrow-right"></i>}
                                            pageCount={props.pageCount}
                                            onPageChange={props.handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            forcePage={props.currentPage}
                                            previousClassName={props.currentPage == 0 ? "hidden" : ""}
                                            nextClassName={props.currentPage == props.pageCount - 1 ? "hidden" : ""}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className="no_data_found">
                            <div className="d-flex justify-content-center">
                                <img src={NoData} height={150} alt="no-data found" />
                            </div>
                        </div>
                    }
                </>

            </div>
        </>
    );
}

export default Closed;