import React from "react";
import { Link } from "react-router-dom";
// import storage from '../../apiServices.js/storage';
import "./sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  // CDBSidebarFooter,
} from "cdbreact";
// import Logo from "../../assets/logo/Logo.png";
import Logo from "../../assets/logo/logo-white-text.png";
// import ShortLogo from "../../assets/icons/head-hunting.png"
function Sidebar() {
  // const [item, setItem] = useState(false);
  // const history = useHistory()
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let getSponsored = sessionStorage.getItem("sponsored");
  let getInvitationCount = sessionStorage.getItem("invitationCount");

  const navigate = useNavigate()

  const clearCandidateData = () => {
    sessionStorage.removeItem("interviewDetails")
    sessionStorage.removeItem("jobId")
    sessionStorage.removeItem("bookingId")
    sessionStorage.removeItem("candidateId")
    navigate("/InterviewSchedule");
    setTimeout(() => {
      window.location.reload();
    }, 5);

  }

  return (
    <div>
      <div className="sidebar ">
        <div className="sidebar-header">
          <h3 className="brand bran1">
            <Link to="/">
              <span id="sidebar-head"></span>
            </Link>
          </h3>
        </div>
        <CDBSidebar>
          {/* <CDBSidebarHeader prefix={<i className="fa fa-bars" onClick={()=>setItem(!item)}/> } ><h4>The ShortListed</h4></CDBSidebarHeader> */}
          <CDBSidebarHeader>
            {" "}
            <img className="logo-img" src={Logo} alt="the.shortlistd"></img>
            {/* <img className="logo-small" height={30} src={ShortLogo} alt="the.shortlistd.com"></img> */}
          </CDBSidebarHeader>

          {sessionStorage.getItem("loginType") === "assessor" ?
            <CDBSidebarContent>
              <CDBSidebarMenu>
                {JSON.parse(getSponsored) ? <Link to="/AssessorDashboard">
                <CDBSidebarMenuItem className={
                    splitLocation[1] === "AssessorDashboard" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                  Dashboard
                </CDBSidebarMenuItem>
                </Link> : <div></div>}  
                {/* <CDBSidebarMenuItem className="font12" icon="th-large" iconSize="lg">
                Dashboard
              </CDBSidebarMenuItem>     */}
                {getSponsored == "false" ? <Link to="/partner-ships">
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "partner-ships" || splitLocation[1] === "ScheduleCandidate" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    <span className="partner_ship_sideBar">Partnerships 
                    {splitLocation[1] === "partner-ships" && <span>{getInvitationCount}</span>}</span>
                  </CDBSidebarMenuItem>
                </Link> : <div></div>}
                <Link to="/InterviewSchedule" onClick={clearCandidateData}>
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "InterviewSchedule" || splitLocation[1] === "ScheduleCandidate" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Interviews
                  </CDBSidebarMenuItem>
                </Link>
                <Link to="/Evaluations">
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "Evaluations" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Evaluations
                  </CDBSidebarMenuItem>
                </Link>
                {/* <Link to="/calendar">
                <CDBSidebarMenuItem className={
                splitLocation[1] === "calendar" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                  Calendar
                </CDBSidebarMenuItem>
              </Link> */}
                <Link to="/assessorSettings">
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "assessorSettings" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Settings
                  </CDBSidebarMenuItem>
                </Link>
              </CDBSidebarMenu>
            </CDBSidebarContent>
            :
            <CDBSidebarContent>
              <CDBSidebarMenu>
                <Link to="/Dashboard">
                <CDBSidebarMenuItem className={
                    splitLocation[1] === "Dashboard" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                Dashboard
              </CDBSidebarMenuItem>
              </Link>  
                {/* <CDBSidebarMenuItem className="font12" icon="th-large" iconSize="lg">
                Dashboard
              </CDBSidebarMenuItem> */}
                {/* <CDBSidebarMenuItem>
                RECRUITMENT
              </CDBSidebarMenuItem> */}
                <h6 className="head-tag mt-2" style={{ paddingLeft: "14%" }}>RECRUITMENT</h6>
                <Link to="/JobBoard"><CDBSidebarMenuItem className={
                  splitLocation[1] === "JobBoard" || splitLocation[1] === "MyJobs" || splitLocation[1] === "Questionnaire" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                  Job Board
                </CDBSidebarMenuItem>
                </Link>
                <Link to="/Interviews"><CDBSidebarMenuItem className={
                  splitLocation[1] === "Interviews" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                  Interviews
                </CDBSidebarMenuItem>
                </Link>

                <Link to="/Candidates"><CDBSidebarMenuItem className={
                  splitLocation[1] === "Candidates" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                  Candidates
                </CDBSidebarMenuItem>
                </Link>
                <Link to="/Assessor">
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "Assessor" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Interview Panel
                  </CDBSidebarMenuItem>
                </Link>
                {/* <Link to="/InterviewSchedule">
                <CDBSidebarMenuItem className={
                splitLocation[1] === "InterviewSchedule" || splitLocation[1] === "ScheduleCandidate" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                Interview Schedule
                </CDBSidebarMenuItem>
              </Link> */}

                <Link to="/QuestionBank">
                  <CDBSidebarMenuItem className={
                    splitLocation[1] === "QuestionBank" || splitLocation[1] === "CreateQuestionBank" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Question Bank
                  </CDBSidebarMenuItem>
                </Link>
              </CDBSidebarMenu>
              {JSON.parse(sessionStorage.getItem("isAdmin")) ?
                <CDBSidebarMenu>
                  {/* <CDBSidebarMenuItem>
                  COMPANY
                </CDBSidebarMenuItem> */}
                  <h6 className="head-tag mt-2" style={{ paddingLeft: "15%" }}>COMPANY</h6>

                  <Link to="/Team">
                    <CDBSidebarMenuItem className={
                      splitLocation[1] === "Team" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                      Team
                    </CDBSidebarMenuItem>
                  </Link>
                  {/* <CDBSidebarMenuItem className="font12" icon="th-large" iconSize="lg">
                  Profile
                </CDBSidebarMenuItem> */}
                  <Link to="/CompanyProfile"><CDBSidebarMenuItem className={
                    splitLocation[1] === "CompanyProfile" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                    Company Profile
                  </CDBSidebarMenuItem>
                  </Link>

                  {/* <CDBSidebarMenuItem className="font12" icon="th-large" iconSize="lg">
                  Subscription
                </CDBSidebarMenuItem> */}
                  <Link to="/Settings">
                    <CDBSidebarMenuItem className={splitLocation[1] === "Settings" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                      Settings
                    </CDBSidebarMenuItem>
                  </Link>

                </CDBSidebarMenu>
                :
                <CDBSidebarMenu>
                  <Link to="/Settings">
                    <CDBSidebarMenuItem className={splitLocation[1] === "Settings" ? "selected" : "font12"} icon="th-large" iconSize="lg">
                      Settings
                    </CDBSidebarMenuItem>
                  </Link>

                </CDBSidebarMenu>}
            </CDBSidebarContent>
          }
          {/* <div className="sidebar-menu">
            <ul style={{ listStyle: "none", marginLeft: "-24px" }}>
              <li>
                <Link to="/dashboard">
                  {item ? (
                    <span
                      className="fas fa-home side_c"
                      id="t-icons"
                      title="House Details"
                    ></span>
                  ) : (
                    <span
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    >
                      {" "}
                      <span className="side_b">Dashboard</span>
                    </span>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/astrologer">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i>
                      {" "}
                      <span className="side_b">Astrologer</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/astrologer">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Astrologer</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/customer">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Customer</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/payment">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Payment</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/question">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Question</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/callhistory">
                  {item ? (
                    <i
                      className="fas fa-user"
                      id="t-icons"
                      title="My Profile"
                    ></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Callhistory</span>
                    </i>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/loginpage">
                  {item ? (
                    <i className="fas fa-user" id="t-icons" title="Log out"></i>
                  ) : (
                    <i className="fas fa-home" id="t-icons" title="My Profile">
                      {" "}
                      <span className="side_b">Log out</span>
                    </i>
                  )}
                </Link>
              </li>
            </ul>
          </div> */}
        </CDBSidebar>
      </div>
    </div>
  );
}

export default Sidebar;
