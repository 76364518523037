import React, { useEffect } from "react";
import './successerror.css';
import Success from '../../assets/icons/success.svg';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal } from "react-bootstrap";
import { BiError } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

export const SuccessError = ({ errorMsg, showError, msg, showSuccess, handleSuccess, handleErrorPopup, infoMsg, showInfoModal, handleInfoPopup }) => {


  return (
    <>
      {/* -------------------- Success Popup -------------------- */}
      <Modal
        className="post_job_success_popup"
        show={showSuccess}
        backdrop="static"
        dialogClassName="post-job-success-modal"
        keyboard={false}
      >
        <Modal.Body
          style={{
            borderLeft: "5px solid #458F5C",
          }}
        >
          <div className="d-flex col-lg-12 col-md-12 col-sm-12 border-left-5 align-items-center">
            <div className="col-lg-2 col-md-2 col-sm-2 text-center">
              <img src={Success} alt="PostJob" />
            </div>
            <div
              style={{ color: "black" }}
              className="p-2 col-lg-9 col-md-9 col-sm-9"
            >
              <label style={{ fontSize: "16px", font: "bold" }}>{msg}</label>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 mt-2 ">
              <button
                className="success-button"
                type="submit"
                onClick={handleSuccess}
              >
                X
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ------------------- Error Popup --------------------- */}
      <Modal
        className="myJobs_success_popup"
        show={showError}
        backdrop="static"
        dialogClassName="myJobs-error-modal"
        keyboard={false}
      >
        <Modal.Body
          style={{
            borderLeft: "5px solid #FF0000",
          }}
        >
          <div className="d-flex col-lg-12 col-md-12 col-sm-12 border-left-5">
            <div className="col-lg-2 col-md-2 col-sm-2 text-center mt-2">
              <BiError style={{ fontSize: "30px", color: "#FF0000" }} />
            </div>
            <div className="text-start col-lg-9 col-md-9 col-sm-9">
              <div
                style={{ fontSize: "18px", font: "bold", color: "#FF0000" }}
              >
                Oops....
              </div>
              <p
                style={{
                  fontSize: "12px",
                  font: "bold",
                  marginTop: "4px",
                  color: "black",
                }}
              >
                {errorMsg}
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 mt-3 ">
              <button
                style={{
                  border: "0px",
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "#FF0000",
                }}
                type="submit"
                onClick={() => { handleErrorPopup() }}
              >
                X
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ------------------- information Popup --------------------- */}
      <Modal
        className="myJobs_info_popup"
        show={showInfoModal}
        backdrop="static"
        dialogClassName="myJobs-info-modal"
        keyboard={false}
      >
        <Modal.Body
          style={{
            borderLeft: "5px solid rgb(255 168 0)",
          }}
        >
          <div className="d-flex col-lg-12 col-md-12 col-sm-12 border-left-5">
            <div className="col-lg-2 col-md-2 col-sm-2 text-center mt-2">
              <i class="bi bi-info-circle"></i>
            </div>
            <div className="text-start col-lg-9 col-md-9 col-sm-9">
              {/* <div
                style={{ fontSize: "18px", font: "bold", color: "#FF0000" }}
              >
                Oops....
              </div> */}
              <p
                style={{
                  fontSize: "12px",
                  font: "bold",
                  marginTop: "4px",
                  color: "black",
                }}
              >
                {infoMsg}
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 mt-3 ">
              <button
                style={{
                  border: "0px",
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "rgb(255 168 0)",
                }}
                type="submit"
                onClick={() => { handleInfoPopup() }}
              >
                X
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ----------------- Server error (500 series HTTP status codes) ----------------

export const ServerError = (props) => {
  const navigate = useNavigate();

  const navigateToPrev = () => {
    navigate(-1);
  }

  return (
    <>
      <div className="serverError_container">
        <div className="server_error">
          <div className="title">
            <h1><span>500 </span>server error</h1>
          </div>
          <div className="para">
            <p>We apologize, but something went wrong.</p>
            <p>Please try again by clicking the button below.</p>
            <button onClick={() => navigateToPrev()}>Try again</button>
          </div>
        </div>
      </div>
    </>
  )
}