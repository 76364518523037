import React, { useEffect, useState } from "react";
import "./profile.css"
import SidebarMenu from "../../../../shared/Sidebar/sidebar";
import Navbar from "../../../../shared/Navbar/navbar";
import Button from "react-bootstrap/Button";
import userIcon from "../../../../assets/icons/user-icon.png";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingIcon from "../../../../assets/gif/loader.gif";
import userAvatar from "../../../../assets/icons/user-icon.png";
import config from "../../../../shared/Config/config.js";
import axios from "axios";
import Select from 'react-select';
import { APIs, globalVar } from "../../../../shared/Config/config.js";
import { SuccessError } from "../../../../shared/Popup/SuccessError";
import { CKEditor } from "ckeditor4-react";
import ImageCrop from "../../../../shared/Popup/ImageCrop/imageCrop";
import AvatarImage from "../../../../shared/Popup/avatarImage/AvatarImage";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../utils/axiosInstance";

function Profile() {
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [selectedSkill, setSelectedSkill] = useState();
    let [skill, setSkill] = useState();
    let [assessorName, setAssessorName] = useState("");
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [phoneNumber, setPhoneNumber] = useState();
    let [countryCode, setCountryCode] = useState();
    let [url, setUrl] = useState();
    let [disableBtn, setDisableBtn] = useState(true);
    let getPhotoId = sessionStorage.getItem("photoId");
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [msg, setMsg] = useState();
    let [showSuccess, setShowSuccess] = useState(false);
    let [aboutAssessor, setAboutAssessor] = useState();
    let [aboutAssessor_2, setAboutAssessor_2] = useState("");
    let [designation, setDesignation] = useState();
    let [showAbout, setShowAbout] = useState(false);
    let [imgCropModal, setImgCropModal] = useState(false);
    let [industryList, setIndustryList] = useState([]);
    let [selectedIndustry, setSelectedIndustry] = useState([]);
    let [assessmentPref, setAssessmentPref] = useState();
    let [assessmentPref_1, setAssessmentPref_1] = useState();
    let [costPerInterview, setCostPerInterview] = useState("");
    let [convertedCredits, setConvertedCredits] = useState("");
    let [discardBtn, setDisacrdBtn] = useState(false);
    const [exceedLimit, setExceedLimit] = useState(false);
    let getLinkedInPage = sessionStorage.getItem("linkedInpage");
    let [skillLimitMsg, setSkillLimitMsg] = useState("");
    let [delSkillIds, setDelSkillIds] = useState([]);
    let ckeConfig = {
        toolbar: [
            { name: "document", items: ["Source"] },
            { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
            { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] },
            { name: "paragraph", items: ["NumberedList", "BulletedList", "-", "Blockquote", "CreateDiv", "-", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
            { name: "links", items: ["Link", "Unlink"] },
            { name: "insert", items: ["Image", "Table"] }, // Remove Horizontal Line and Special Character buttons
            { name: "styles", items: ["Format", "Font", "FontSize"] },
            { name: "colors", items: ["TextColor", "BGColor"] },
            { name: "tools", items: ["Maximize", "ShowBlocks"] },
            { name: "others", items: ["-"] },
        ],
        removeButtons: "Paste,PasteText,PasteFromWord,About,Image,Save,NewPage,Print,Templates,Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Flash,IFrame,PageBreak,Anchor,Save,Preview,Cut,Copy,Source,Maximize,Subscript,Superscript,Link,Unlink,Format,Table, Strikethrough",
        extraPlugins: "autolink,autoembed,link",
        versionCheck: false
    };

    useEffect(() => {
        getViewProfile();
        // getSkillList();
        getIndustryList();
        setTimeout(() => {
            setShowAbout(true)
        }, 500);
    }, []);

    let handleOnChange = (phone, mobileData) => {
        console.log(phone, mobileData)
        let cd = mobileData.dialCode.length;
        let number = phone.slice(cd);
        setPhoneNumber(number);
        setMobile(phone);
        // setMobile_2(phone);
        // setPhoneNumber_2(number);
        setCountryCode(mobileData.dialCode);
        if (number?.length === 10 && assessorName !== "") {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
        setDisacrdBtn(true);
    }

    let handleInputChange = (e, type) => {
        if (type == "name") {
            // setInputChangedName(true);
            if (e.target.value !== "" && phoneNumber?.length === 10) {
                setDisableBtn(false);
            } else {
                setDisableBtn(true);
            }
            setAssessorName(e.target.value);
        } else if (type == "designation") {
            setDisableBtn(false);
            setDesignation(e.target.value);
        } else if (type == "url") {
            if (e.target.value == "") {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
            setUrl(e.target.value);
        } else if (type == "cost") {
            if (e.target.value == "") {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
            setCostPerInterview(e.target.value);
            setConvertedCredits({
                convertedCredits: (e.target.value / convertedCredits.costPerCredit).toFixed(2),
                costPerCredit: convertedCredits.costPerCredit,
                currency: convertedCredits.currency,
            }
            )
        }
        setDisacrdBtn(true);
    }

    let discardChanges = () => {
        getViewProfile();
    }

    let handleErrorPopup = () => {
        setShowError(false);
    }

    let handleSuccess = () => {
        setShowSuccess(false);
    }

    const convertUrlsToLinks = (text) => {
        const urlRegex = /(<a\b[^>]*>.*?<\/a>|(?:https?:\/\/)?(?:www\.)?([^\s<]*\.(?:in|org|com|ai))(?:[^\s<]*)|[^@\s]+@[^\s>]+|www\.[^\s<]*)/g;
        return text.replace(urlRegex, (match, urlWithoutPrefix) => {
            if (match.startsWith('<a')) {
                return match; // Return existing anchor tags unchanged
            } else {
                const url = urlWithoutPrefix ? `https://${urlWithoutPrefix}` : match;
                return `<a href="${url}" target="_blank">${match}</a>`;
            }
        });
    };

    const aboutmeAssessor = (e) => {
        let temp = e.editor.getData().replace(/&nbsp;/g, ' ');

        const convertedText = convertUrlsToLinks(temp);

        const wordCount = convertedText.length;

        if (wordCount > globalVar.maxCharCount) {
            setExceedLimit(true);
        } else {
            setExceedLimit(false);
        }
        setAboutAssessor(convertedText);
        setDisableBtn(false);
        setDisacrdBtn(true);
    };

    // let removePhoto = () => {
    //     sessionStorage.setItem("photoId", 0)
    // }

    let onChangeSkill = (val) => {
        setSelectedSkill(val);
        setDisableBtn(false);
        // -------------- add skill -------------
        let tempSkillId;
        val.map((itm) => (
            tempSkillId = itm.value
        ))
        // ---------- disbale skill ------------
        let cancelledValue = selectedSkill?.filter(
            (prevValue) => !val.includes(prevValue)
        );
        let tempDisableSkillId;
        cancelledValue.map((item) => (
            tempDisableSkillId = item.value
        ))
        if (cancelledValue.length !== 0) {
            disableSkills([tempDisableSkillId]);
        } else {
            uploadSkills(tempSkillId);
        }
        // setDisacrdBtn(true);
    }

    let onChange_industry = (v) => {
        setSelectedIndustry({
            value: v.value,
            label: v.label
        });
        getSkillsBasedIndus(v.label);
        setDisableBtn(false);
    }

    let handleAsssessmentPref = (e, type) => {
        if (type == "domainSkill_false") {
            setAssessmentPref(true);
            setAssessmentPref_1(true);
        } else {
            setAssessmentPref(false);
            setAssessmentPref_1(false);
        }
    }

    let onClose = () => {
        setImgCropModal(false);
    };

    const getViewProfile = () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9005",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    }
                }
            }
        }


        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = APIs.assessorAPI + payLoad;

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                if (result.data.json.response.statuscode === "0") {
                    let temp = result.data.json.response.data;
                    setDisableBtn(true);
                    setDisacrdBtn(false);
                    setAssessorName(temp?.name);
                    setEmail(temp?.email);
                    setMobile(temp?.mobile);
                    setDesignation(temp?.designation);
                    setAboutAssessor(decodeURIComponent(temp?.aboutMe));
                    setUrl(temp?.linkedInUrl);
                    let number = temp?.mobile.slice(-10);
                    setPhoneNumber(number);
                    let countryCode = temp?.mobile.slice(1, 3);
                    setCountryCode(countryCode);
                    sessionStorage.setItem("industry", temp.industry)
                    let arr = [];
                    temp.skill.map((item) => (
                        arr.push({
                            "value": item.skillId,
                            "label": item.skillName
                        })
                    ))
                    setSelectedSkill(arr)
                    let arrInd = [];
                    arrInd.push({
                        "value": temp.industry,
                        "label": temp.industry
                    })
                    setSelectedIndustry(arrInd);
                    setAssessmentPref(temp?.anyDomainSkill);
                    setAssessmentPref_1(temp?.anySoftSkill);
                    getSkillsBasedIndus(temp?.industry);
                    setCostPerInterview(temp?.creditsPerAssessment);
                    setConvertedCredits(
                        {
                            convertedCredits: temp?.convertedCredits,
                            costPerCredit: temp?.costPerCredit,
                            currency: temp?.currency
                        });

                    sessionStorage.setItem("userName", temp?.name);
                    sessionStorage.setItem("industry", temp.industry);
                } else {
                    setShowError(true);
                    setErrorMsg(result.data.json.response.statusmessage);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    const updateProfile = () => {
        setLoading(true);

        let json = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9006",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        // "assessorId": assessorView.assessorId,
                        "mobile": phoneNumber,
                        "country_code": "+" + countryCode,
                        "name": assessorName,
                        // "status": "Busy",
                        "linkedInUrl": url,
                        "industry": selectedIndustry.label,
                        "designation": designation,
                        "aboutMe": encodeURIComponent(aboutAssessor),
                        "anyDomainSkill": assessmentPref,
                        "anySoftSkill": assessmentPref_1,
                        "creditsPerAssessment": getLinkedInPage ? costPerInterview : undefined
                    }
                }
            }
        }


        let payLoad = encodeURIComponent(JSON.stringify(json));
        let apiUrl = APIs.assessorAPI + payLoad;

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response;
                // setAssessorView(temp);
                if (temp.statuscode === "0") {
                    getViewProfile();
                    setShowSuccess(true);
                    setDisacrdBtn(false);
                    setMsg("Profile updated successfully");
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    // let getSkillList = () => {
    //     setLoading(true);
    //     let searchJson = {
    //         "json": {
    //             "request": {
    //                 servicetype: 6,
    //                 functiontype: 6001,
    //                 data: {

    //                 }
    //             }
    //         }
    //     }

    //     let searchApiUrl = config.baseUrl + "/JobzTop/SearchJob?request=" + encodeURIComponent(JSON.stringify(searchJson));

    //     axiosInstance.post(searchApiUrl)
    //         .then((result) => {
    //             setLoading(false);
    //             let temp = result.data.json.response.data
    //             setSkill(temp);
    //         }).catch((error) => {
    //             setLoading(false);
    //             if (error) {
    //                 // setLoading(false);
    //                 // 
    //             }
    //         });
    // }

    let uploadSkills = (skillId) => {
        setLoading(true);
        let searchJson = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9012",
                    "data": {
                        "skillId": skillId
                    },
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    }
                }
            }
        }

        let searchApiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(searchJson));

        axiosInstance.post(searchApiUrl)
            .then((result) => {
                setLoading(false);
                if (result.data.json.response.statuscode == 0) {
                    setShowSuccess(false);
                    setMsg(result.data.json.response.statusmessage)
                } else {
                    getViewProfile();
                    setShowError(true);
                    setErrorMsg(result.data.json.response.statusmessage)
                    setDisableBtn(true);
                }
                // getViewProfile();
            }).catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    let disableSkills = (skillId) => {
        setLoading(true);
        let searchJson = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9011",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "data": {
                        "enabled": [],
                        "disabled": skillId
                    }
                }
            }
        }

        let searchApiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(searchJson));

        axiosInstance.post(searchApiUrl)
            .then((result) => {
                setLoading(false);
                // getViewProfile();
                if (result.data.json.response.statuscode == 0) {
                    setShowSuccess(false);
                    setMsg(result.data.json.response.statusmessage)
                } else {
                    getViewProfile();
                    setShowError(true);
                    setErrorMsg(result.data.json.response.statusmessage);
                    setDisableBtn(true);
                }
            }).catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    let getIndustryList = () => {
        setLoading(true);
        let searchJson = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1040",
                    "industry": "_ALL_"
                }
            }
        }

        let searchApiUrl = APIs.questionBankApi + encodeURIComponent(JSON.stringify(searchJson));

        axiosInstance.post(searchApiUrl)
            .then((result) => {
                setLoading(false);
                if (result.data.json.response.statuscode == 0) {
                    let temp = result.data.json.response.data
                    setIndustryList(temp);
                    setShowSuccess(false);
                    setMsg(result.data.json.response.statusmessage)
                } else {
                    getViewProfile();
                    setShowError(true);
                    setErrorMsg(result.data.json.response.statusmessage)
                    setDisableBtn(true);
                }
            }).catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    const getSkillsBasedIndus = (indus) => {
        setLoading(true);
        let searchJson = {
            "json": {
                "request": {
                    "servicetype": "8",
                    "functiontype": "8005",
                    "data": {
                        "domain": indus
                    }
                }
            }
        }


        let searchApiUrl = APIs.skillApi + encodeURIComponent(JSON.stringify(searchJson));

        axiosInstance.post(searchApiUrl)
            .then((result) => {
                setLoading(false);
                if (result.data.json.response.statuscode == 0) {
                    let temp = result.data.json.response.data.skill
                    setSkill(temp);
                } else {
                    setShowError(true);
                    setErrorMsg(result.data.json.response.statusmessage)
                }
            }).catch((error) => {
                setLoading(false);
                if (error) {
                    setLoading(false);
                    
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            < SuccessError
                errorMsg={errorMsg}
                showError={showError}
                showSuccess={showSuccess}
                msg={msg}
                handleErrorPopup={handleErrorPopup}
                handleSuccess={handleSuccess} />
            {/* ------------> image crop component <------------- */}
            {
                imgCropModal &&
                // <ImageCrop
                //     photoFile={photoFile}
                //     uploadPhoto={uploadPhoto}
                //     imgCropModal={imgCropModal}
                //     setImgCropModal={setImgCropModal} />
                <AvatarImage
                    imgCropModal={imgCropModal}
                    onClose={onClose}
                    setShowError={setShowError}
                    setErrorMsg={setErrorMsg}
                    setShowSuccess={setShowSuccess}
                    setMsg={setMsg}
                />
            }
            <div className="main_profile">
                {/* <SidebarMenu></SidebarMenu> */}
                <div className="profile_content">
                    {/* <Navbar></Navbar> */}
                    <div className="page-content">
                        {/* <div className="d-flex heading">
                            <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 profile-head" style={{ paddingLeft: "51px" }}>
                                <h4 style={{ fontSize: "18px", marginBottom: "0" }}>My Profile</h4>
                                <h6 style={{ fontSize: "12px", marginBottom: "0" }}>Manage profile settings</h6>
                            </div>
                        </div> */}

                        <div className="container-fluid">
                            <div className="row profile_block_2">
                                <div className="col-lg-4 upload_Photo">
                                    {
                                        getPhotoId !== "0" ?
                                            <img
                                                // src={userIcon}
                                                src={APIs.showPhoto + "/showimage?id=" + getPhotoId + "&type="}
                                                className="cmp-img"
                                                alt="Company Icon"
                                                onError={(e) => e.target.src = userAvatar}
                                            />
                                            : <img src={userAvatar} alt="user" />
                                    }
                                    {/* <div> */}
                                    <label for="file-upload" className="custom-file-upload" onClick={() => setImgCropModal(true)}>
                                        UPLOAD PHOTO
                                    </label>
                                    {/* <input id="file-upload" type="file" accept="image/*" onChange={(e) => onSelectPhotoFile(e)} /> */}
                                    <label style={{ marginTop: "-1px" }}>Supported formats</label>
                                    <label style={{ marginTop: "-1px" }}>.jpg and .png</label>
                                    {/* </div> */}
                                    {/* <div className="removePhoto">
                                        <label onChange={() => removePhoto()} >
                                            REMOVE PHOTO
                                        </label>
                                    </div> */}
                                </div>
                                <div className="col-lg-8 profile_fields">
                                    <div className="labels">
                                        <div className="row name">
                                            <div id="label" className="col-lg-3">
                                                <label>Name of the Assessor*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    className="font12"
                                                    type="text"
                                                    name="companyName"
                                                    value={assessorName}
                                                    onChange={(e) => handleInputChange(e, "name")}
                                                    placeholder="Enter Your Full Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="row email">
                                            <div id="label" className="col-lg-3">
                                                <label>Email ID*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    type="text"
                                                    name="maxTestScore"
                                                    className="font12"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter Email"
                                                    disabled
                                                    style={{ cursor: "not-allowed" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row phone">
                                            <div id="label" className="col-lg-3">
                                                <label>Contact Number*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <PhoneInput
                                                    className="font12"
                                                    country={"91"}
                                                    countryCodeEditable={false}
                                                    // disableDropdown={true}
                                                    value={mobile}
                                                    onChange={(value, countryData) =>
                                                        handleOnChange(value, countryData)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row designation">
                                            <div id="label" className="col-lg-3">
                                                <label>Designation</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    type="text"
                                                    className="font12"
                                                    value={designation}
                                                    onChange={(e) => handleInputChange(e, "designation")}
                                                    placeholder="Enter Designation"
                                                />
                                            </div>
                                        </div>
                                        <div className="row url">
                                            <div id="label" className="col-lg-3">
                                                <label>Profile URL</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    type="serch"
                                                    placeholder="Enter Professional Profile link"
                                                    className="font12"
                                                    value={url}
                                                    onChange={(e) => handleInputChange(e, "url")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row industry">
                                            <div id="label" className="col-lg-3">
                                                <label>Industry*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="Enter industry"
                                                    className="font12"
                                                    value={inputChangedIndustry ? industry_2 : industry}
                                                    onChange={(e) => handleInputChange(e, "industry")}
                                                /> */}
                                                <Select
                                                    isMulti={false}
                                                    value={selectedIndustry}
                                                    onChange={(o) => onChange_industry(o)}
                                                    placeholder={"Industry"}
                                                    options={industryList.map((itm) => {
                                                        return { value: itm.industryId, label: itm.industry }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row name" style={{
                                            alignItems: "flex-start",
                                            marginTop: "14px"
                                        }}>
                                            <div id="label" className="col-lg-3" style={{ paddingTop: "0" }}>
                                                <label>Assessment Preference*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                {/* <Form.Control
                                                    className="font12"
                                                    type="radio"
                                                    label={"Assessment Preference"}
                                                    name="Assessment"
                                                    onChange={(e) => handleAsssessmentPref(e)}
                                                    placeholder="Enter Your Full Name"
                                                    onClick={() => disablingBtn(false)}
                                                /> */}
                                                <div className="wildCard_skill">
                                                    <input style={{ width: "auto", marginTop: "7px" }} checked={assessmentPref} id="specifyColor"
                                                        name="domainSkill_false" type="radio" onChange={(e) => handleAsssessmentPref(e, "domainSkill_false")} />
                                                    <div style={{ marginLeft: "14px" }}>
                                                        <label style={{
                                                            fontWeight: "500",
                                                            fontSize: "12px"
                                                        }}>Industry Expert</label>
                                                        <p className="font12">Can assess all skills in the selected industry</p>
                                                    </div>
                                                </div>
                                                <div className="specific_skill">
                                                    <input style={{ width: "auto", marginTop: "7px" }} checked={!assessmentPref} id="specifyColor"
                                                        name="domainSkill_false" type="radio" onChange={(e) => handleAsssessmentPref(e, "domainSkill")} />
                                                    <div style={{ marginLeft: "14px" }}>
                                                        <label style={{
                                                            fontWeight: "500",
                                                            fontSize: "12px"
                                                        }}>Skill Specialist</label>
                                                        <p className="font12">Can assess specific skills within the selected industry</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!assessmentPref && <div className="row skill">
                                            <div id="label" className="col-lg-3">
                                                <label>Skills*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Select
                                                    isMulti
                                                    value={selectedSkill}
                                                    onChange={(value) => onChangeSkill(value)}
                                                    placeholder={"Select Skills"}
                                                    options={skill?.map((item, index) => {
                                                        return { value: item?.id, label: item?.skillName }
                                                    })}
                                                // onClick={() => disablingBtn(false)}
                                                />
                                            </div>
                                        </div>}
                                        {getLinkedInPage && <div className="row interview_cost">
                                            <div id="label" className="col-lg-3">
                                                <label>Interview Rate (in {convertedCredits.currency})*</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    className="font12"
                                                    type="number"
                                                    name="companyName"
                                                    value={costPerInterview}
                                                    onChange={(e) => handleInputChange(e, "cost")}
                                                    placeholder="Enter your charge per interview"
                                                    min="0"
                                                />
                                            </div>
                                            <div className="col-lg-3"></div>
                                            <div className="col-lg-9" style={{ fontSize: "12px", marginTop: "-5px" }}>Converted to credits : <label>{convertedCredits.convertedCredits}</label></div>
                                        </div>
                                        }
                                        {/* {getLinkedInPage && <div className="row interview_cost">
                                            <div id="label" className="col-lg-3">
                                                <label>Converted to credits</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <Form.Control
                                                    className="font12"
                                                    type="number"
                                                    name="companyName"
                                                    value={convertedCredits.convertedCredits}
                                                    onChange={(e) => handleInputChange(e, "convertedCred")}
                                                    disabled
                                                />
                                            </div>
                                        </div>} */}
                                        <div className="row aboutMe">
                                            <div id="label" className="col-lg-3">
                                                <label>About me</label>
                                            </div>
                                            <div className="col-lg-9" style={{
                                                width: "60.5%",
                                                paddingTop: "2%"
                                            }}>
                                                {showAbout && <>
                                                    <CKEditor
                                                        config={ckeConfig}
                                                        name="about"
                                                        initData={aboutAssessor}
                                                        value={aboutAssessor}
                                                        onChange={(e) => aboutmeAssessor(e)}
                                                    />
                                                    <div className="d-flex justify-content-end">
                                                        {aboutAssessor && (<>
                                                            <p style={{ color: "gray", fontSize: "12px" }}>
                                                                {aboutAssessor.length} / {globalVar.maxCharCount}{" "} characters {" "}
                                                            </p>{aboutAssessor.length > globalVar.maxCharCount && <span style={{ marginLeft: "4px", color: "red" }} className="danger font12">{" "} Exceeded limit</span>}
                                                        </>
                                                        )}
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className="btns">
                                            {discardBtn && <label onClick={() => discardChanges()}>DISCARD CHANGES</label>}

                                            <Button onClick={updateProfile}
                                                disabled={disableBtn || exceedLimit}>SAVE CHANGES</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;