import React, { useEffect, useState } from "react";
import "./partnerShips.css"
import Navbar from "../../../shared/Navbar/navbar";
import SidebarMenu from "../../../shared/Sidebar/sidebar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pending from "./Pending/pending";
import Confirmed from "./Confirmed/confirmed";
import Declined from "./Declined/declined";
import { APIs } from "../../../shared/Config/config.js";
import LoadingIcon from "../../../assets/gif/loader.gif";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SuccessError } from "../../../shared/Popup/SuccessError";
import Closed from "./Closed/closed";
import { axiosInstance } from "../../../utils/axiosInstance";

function PartnerShips() {
    let navigate = useNavigate();
    let [handleTabs, setHandleTabs] = useState("pending");
    let [stateInt, setStateInt] = useState(1);
    let [loading, setLoading] = useState(false);
    let [showError, setShowError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();
    let [getInvitaionList, setGetInvitaionList] = useState([]);
    let [pageCount, setPageCount] = useState(0);
    let [currentPage, setCurrentPage] = useState(0);
    let [searchVal, setSearchVal] = useState("");
    let getInvitationCount = sessionStorage.getItem("invitationCount");

    useEffect(() => {
        InvitaionList(0, 1, searchVal);
        sessionStorage.setItem("assessorSettingTabs", "ProfileTab");
    }, []);

    const handleErrorPopup = () => {
        setShowError(!showError)
    }

    const handleJobTabs = (e) => {
        setHandleTabs(e);
        if (e === "pending") {
            InvitaionList(0, 1, "");
            setStateInt(1);
            setSearchVal("");
        } else if (e === "confirmed") {
            InvitaionList(0, 2, "");
            setStateInt(2);
            setSearchVal("");
        } else if (e === "declined") {
            InvitaionList(0, 3, "");
            setStateInt(3);
            setSearchVal("");
        } else if (e === "closed") {
            InvitaionList(0, 4, "");
            setStateInt(4);
            setSearchVal("");
        }
    }

    let handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
        InvitaionList(selectedPage, stateInt, searchVal);
    }

    let per_page = 10;
    let offset;
    const InvitaionList = (currentPageCount, state, name) => {
        setCurrentPage(currentPageCount)
        offset = currentPageCount * per_page;
        setSearchVal(name);

        setLoading(true);
        let payLoad = {
            "json": {
                "request": {
                    "servicetype": "9",
                    "functiontype": "9043",
                    "session": {
                        "sessionid": sessionStorage.getItem("sessionid")
                    },
                    "records": {
                        "offset": offset,
                        "limit": per_page
                    },
                    "data": {
                        "companyName": name.trim(),
                        "state": state
                    }
                }
            }
        }


        let apiUrl = APIs.assessorAPI + encodeURIComponent(JSON.stringify(payLoad));
        // let apiUrl = "http://192.168.1.12:8080/JobzTop/Assessor?request=" + encodeURIComponent(JSON.stringify(payLoad));

        axiosInstance.post(apiUrl)
            .then((result) => {
                setLoading(false);
                let temp = result.data.json.response
                if (temp.statuscode === "0") {
                    setGetInvitaionList(temp.data.invitationlist);
                    setPageCount(Math.ceil(temp.data.count / per_page));
                    sessionStorage.setItem("invitationCount", temp.data.count);
                } else {
                    setShowError(true);
                    setErrorMsg(temp.statusmessage);
                }
            }).catch((error) => {
                if (error) {
                    setLoading(false);
                    if (error.response.status >= 400 && error.response.status < 500) {
                        setShowError(true);
                        setErrorMsg(error.response.data.error.message);
                    } else {
                        
                    }
                }
            });
    }

    return (
        <>
            {loading && (
                <div className="loader-wrapper">
                    <div className="spin">
                        <img src={LoadingIcon} />
                    </div>
                </div>
            )}
            <SuccessError
                errorMsg={errorMsg}
                showError={showError}
                handleErrorPopup={handleErrorPopup} />
            <div className="settings_container">
                <SidebarMenu></SidebarMenu>
                <div className="settings_content">
                    <Navbar></Navbar>
                    <div className="heading">
                        <h3>Partnerships</h3>
                        <p>Manage your corporate collaborations</p>
                    </div>
                    <div className="content">
                        <div>
                            <Tabs
                                // defaultActiveKey="ProfileTab"
                                id="justify-tab-example"
                                // className="mb-3"
                                activeKey={handleTabs}
                                onSelect={(e) => handleJobTabs(e)}
                            >
                                {/* --------------===> pending partnership <===----------------- */}
                                <Tab eventKey="pending" title={
                                    <span className="title_partner">Pending Partnership
                                        {handleTabs === "pending" && <span className="invitation_count">{getInvitationCount}</span>}
                                    </span>}>
                                    {handleTabs === "pending" &&
                                        < Pending
                                            getInvitaionList={getInvitaionList}
                                            handlePageClick={handlePageClick}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            InvitaionList={InvitaionList}
                                            stateInt={stateInt} />}
                                </Tab>

                                {/* --------------===> confirmed partnership <===----------------- */}
                                <Tab eventKey="confirmed" title={
                                    <span className="title_partner">Confirmed Partnership
                                        {handleTabs === "confirmed" && <span className="invitation_count">{getInvitationCount}</span>}
                                    </span>}>
                                    {handleTabs === "confirmed" &&
                                        <Confirmed
                                            getInvitaionList={getInvitaionList}
                                            handlePageClick={handlePageClick}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            InvitaionList={InvitaionList}
                                            stateInt={stateInt} />}
                                </Tab>

                                {/* --------------===> declined partnership <===----------------- */}
                                <Tab eventKey="declined" title={
                                    <span className="title_partner">Declined Partnership
                                        {handleTabs === "declined" && <span className="invitation_count">{getInvitationCount}</span>}
                                    </span>}>
                                    {handleTabs === "declined" &&
                                        <Declined
                                            getInvitaionList={getInvitaionList}
                                            handlePageClick={handlePageClick}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            InvitaionList={InvitaionList}
                                            stateInt={stateInt} />}
                                </Tab>

                                {/* --------------===> closed partnership <===----------------- */}
                                <Tab eventKey="closed" title={
                                    <span className="title_partner">Closed Partnership
                                        {handleTabs === "closed" && <span className="invitation_count">{getInvitationCount}</span>}
                                    </span>}>
                                    {handleTabs === "closed" &&
                                        <Closed
                                            getInvitaionList={getInvitaionList}
                                            handlePageClick={handlePageClick}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            InvitaionList={InvitaionList}
                                            stateInt={stateInt} />}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PartnerShips;