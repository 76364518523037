import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../../shared/Sidebar/sidebar";
import "./assessorSettings.css"
import Navbar from "../../../../shared/Navbar/navbar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Profile from "../Profile/profile";
import Integration from "../Integrations/Integration";
import ChangePassword from "../../../../shared/components/changePassword/ChangePassword";
import InterviewAvailability from "../../../../shared/components/InterviewAvalSetting/InterviewAvailability";
import { useLocation } from "react-router-dom";


function AssessorSettings() {
    let location = useLocation();
    let [profileTab, setProfileTab] = useState("ProfileTab");
    let getaAssessorSettingTabs = sessionStorage.getItem("assessorSettingTabs");
    let getLinkedInPage = sessionStorage.getItem("linkedInpage")

    const handleJobTabs = (e) => {
        setProfileTab(e);
        sessionStorage.setItem("assessorSettingTabs", e);
    }

    return (
        <>
            <div className="settings_container">
                <SidebarMenu></SidebarMenu>
                <div className="settings_content">
                    <Navbar></Navbar>
                    <div className="heading">
                        <h3>Settings</h3>
                        <p>Manage your account settings and preferences</p>
                    </div>
                    <div className="content">
                        <div>
                            <Tabs
                                // defaultActiveKey="ProfileTab"
                                id="justify-tab-example"
                                // className="mb-3"
                                activeKey={profileTab}
                                onSelect={(e) => handleJobTabs(e)}
                            >
                                {/* --------------===> Profile <===----------------- */}
                                <Tab eventKey={"ProfileTab"} title="Profile">
                                    {<Profile />}
                                </Tab>

                                {/* --------------===> Interview Settings <===----------------- */}
                                <Tab eventKey="InterviewSettingsTab" title="Interview Settings">
                                    {<InterviewAvailability isAssessor={true} />}
                                </Tab>

                                {/* --------------===> Integrations <===----------------- */}
                                <Tab eventKey="IntegrationsTab" title="Integrations">
                                    {<Integration setProfileTab={setProfileTab} />}
                                </Tab>

                                {!getLinkedInPage && <Tab eventKey="changePassword" title="Change Password">
                                    {<ChangePassword isAssessor={true} />}
                                </Tab>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssessorSettings;