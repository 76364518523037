import React, { useState } from "react";
import './calendar.css'
import Navbar from "../../../shared/Navbar/navbar";
import SidebarMenu from "../../../shared/Sidebar/sidebar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function Calendar() {
    let [calendarView, setCalendarView] = useState(false);

    const events = [
        { title: 'Meeting', start: new Date() }
    ]

    return (
        <>
            <div className="Assessor_calendar_container">
                <SidebarMenu></SidebarMenu>
                <div className="Assessor_calendar">
                    <Navbar></Navbar>
                    <div className="heading">
                        <h3>Calendar</h3>
                        <p>Day, week and month wise interview details</p>
                    </div>
                    <div className="content">
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin
                            ]}
                            initialView='timeGridDay'
                            weekends={false}
                            events={events}
                            headerToolbar={{
                                left: "prev,next",
                                right: "timeGridDay,dayGridMonth",
                            }}
                        // eventContent={renderEventContent}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Calendar;